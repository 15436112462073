import axios from "axios";
import {
  AppointmentsType,
  DayScheduleActionsNew,
  ResourceLocationStatusEnum,
} from "shared/Enums";
import { formatResponseWarning } from "shared/helpers/common.helper";

import { ResponseDto, VisitDetailsNewI } from "@interfaces";

import {
  apiCreateVisit,
  apiDeleteVisit,
  apiDeleteVisitResource,
  apiEditVisit,
  apiGetExifVisitResources,
  apiGetMediumVisitResources,
  apiGetVisitData,
  apiGetVisitName,
  apiGetVisitsNameIdList,
  apiUploadVisitResources,
} from "./Api/Visits.api.service";
import { getLocationViewData } from "./Locations.service";

const moment = require("moment-timezone");

export const UploadVisitResources = (
  id: string,
  body: any,
  name: string
): Promise<any> => {
  return apiUploadVisitResources(id, body, name)
    .then((data) => {
      return axios
        .all([apiGetMediumVisitResources([id]), apiGetExifVisitResources([id])])
        .then((resources: any) => {
          return {
            count: data.data.data?.length,
            items: data.data.data?.map((i: any) => {
              const mediumSrc = resources[0]?.data[id]?.medium.find(
                (r: { file: { uuid: string }; imagecache: string }) =>
                  r.file.uuid === i.id
              )?.imagecache;
              const object = resources[1].data[id] || {};
              const finded: any = Object.values(object).find(
                (exif: any) => exif.image_uuid === i.id
              );
              return {
                src: i.attributes.uri.url,
                mediumSrc: mediumSrc,
                id: i.id,
                timeStatus:
                  finded?.statuses.datetime_status ||
                  ResourceLocationStatusEnum.unknown,
                geoStatus:
                  finded?.statuses.geolocation_status ||
                  ResourceLocationStatusEnum.unknown,
              };
            }),
          };
        });
    })
    .catch((err) => err);
};

export const getVisitsNameIdList = (
  pageNumber: number,
  timezone: string,
  id: string
) => {
  return apiGetVisitsNameIdList(20, pageNumber, timezone, id)
    .then((resp) => {
      const response = formatResponseWarning(resp.data);
      return {
        items:
          response.data?.map((item: any) => {
            const location = resp.data.included?.find(
              (i: ResponseDto<any>) =>
                i.type === "ups_location--ups_location" &&
                i.id === item.relationships.location?.data?.id
            );

            return {
              id: item.id,
              title: `Visit to ${location?.attributes.label}`,
              locationId: location?.id,
            };
          }) || [],
        hasMore: !(response.data?.length < 20),
      };
    })
    .catch((err) => err);
};

export const getVisitName = (id: string) => {
  return apiGetVisitName(id)
    .then((resp) => {
      const location = resp.data.included?.find(
        (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
      );

      return {
        id: resp.data.data.id,
        title: `Visit to ${location?.attributes.label}`,
        locationId: location?.id,
      };
    })
    .catch((err) => err);
};

export const deleteVisitResource = (
  id: string,
  visitId: string,
  resourcesids: string[]
): Promise<any> => {
  return apiDeleteVisitResource(id)
    .then(() => {
      editVisit(visitId, resourcesids)
        .then((i) => {})
        .catch((err) => err);
    })
    .catch((err) => err);
};

export const editVisit = (
  visitId: string,
  ids: string[]
): Promise<any> => {
  return apiEditVisit(visitId, {
    data: {
      type: "ups_visit--ups_visit",
      id: visitId,
      relationships: {
        images: {
          data: ids.map((i) => {
            return {
              type: "file--file",
              id: i,
            };
          }),
        },
      },
    },
  }).catch((err) => err);
};

export const CreateVisit = (
  timezone: string,
  locationId: string,
  dayId: string,
  status: string
) => {
  return apiCreateVisit({
    data: {
      type: "ups_visit--ups_visit",
      attributes: {
        date: moment().utc().tz(timezone).format("YYYY-MM-DDTHH:mm:ssZZ"),
        visit_status: status,
      },
      relationships: {
        location: {
          data: {
            type: "ups_location--ups_location",
            id: locationId,
          },
        },
        day: {
          data: {
            type: "ups_day--ups_day",
            id: dayId,
          },
        },
      },
    },
  })
    .then((response) => {
      return response.data.data.id;
    })
    .catch((err) => err);
};

export const DeleteVisit = (id: string) => {
  return apiDeleteVisit(id).catch((err) => err);
};

export const getVisitData = (
  visitId: string,
  params: {
    userIdForTask?: string;
    userIdForNotes?: string;
  }
) => {
  return apiGetVisitData(visitId)
    .then((response) => {
      const visitsResources: ResponseDto<any>[] =
        response.data.included?.filter(
          (i: ResponseDto<any>) => i.type === "file--file"
        ) || [];

      return getLocationViewData(
        response.data.data.relationships.location.data?.id,
        0,
        params
      )
        .then((location) => {
          if (visitsResources?.length) {
            return axios
              .all([
                apiGetMediumVisitResources([visitId]),
                apiGetExifVisitResources([visitId]),
              ])
              .then((resources: any) => {
                return {
                  title: "",
                  id: response.data.data.id,
                  status: response.data.data.attributes.visit_status,
                  appointment:
                    response.data.data.relationships.appointment.data,
                  location: location,
                  arrival: response.data.data.attributes.date,
                  isVirtual: response.data.data.attributes.is_virtual,
                  dateEnd: response.data.data.attributes.date_end,
                  resources: {
                    count: visitsResources?.length,
                    items: visitsResources?.map((i) => {
                      const mediumSrc = resources[0]?.data[
                        visitId
                      ]?.medium.find(
                        (r: { file: { uuid: string }; imagecache: string }) =>
                          r.file.uuid === i.id
                      )?.imagecache;
                      const object = resources[1].data[visitId] || {};
                      const finded: any = Object.values(object).find(
                        (exif: any) => exif.image_uuid === i.id
                      );
                      return {
                        src: i.attributes.uri.url,
                        mediumSrc: mediumSrc,
                        id: i.id,
                        timeStatus:
                          finded?.statuses.datetime_status ||
                          ResourceLocationStatusEnum.unknown,
                        geoStatus:
                          finded?.statuses.geolocation_status ||
                          ResourceLocationStatusEnum.unknown,
                      };
                    }),
                  },
                } as VisitDetailsNewI;
              });
          } else {
            return {
              title: "",
              id: response.data.data.id,
              status: response.data.data.attributes.visit_status,
              appointment: response.data.data.relationships.appointment.data,
              location: location,
              arrival: response.data.data.attributes.date,
              isVirtual: response.data.data.attributes.is_virtual,
              dateEnd: response.data.data.attributes.date_end,
              resources: {
                count: 0,
                items: [],
              },
            } as VisitDetailsNewI;
          }
        })
        .catch((err) => err);
    })
    .catch((err) => err);
};

export const editVisitStatus = (
  id: string,
  body: DayScheduleActionsNew,
  timezone: string,
  isVirtual?: AppointmentsType
): Promise<any> => {
  let action = "";
  let date = {};
  switch (body) {
    case DayScheduleActionsNew.skipVisit:
      action = "skipped";
      break;
    case DayScheduleActionsNew.unskipVisit:
      action = "pending";
      break;
    case DayScheduleActionsNew.completeVisit:
      action = "completed";
      date = {
        date_end: moment().utc().tz(timezone).format("YYYY-MM-DDTHH:mm:ssZZ"),
      };
      break;
  }
  const isVirtualObj = isVirtual
    ? {
        is_virtual: isVirtual === AppointmentsType.call,
      }
    : {};
  return apiEditVisit(id, {
    data: {
      type: "ups_visit--ups_visit",
      id: id,
      attributes: {
        visit_status: action,
        ...isVirtualObj,
        ...date,
      },
    },
  }).catch((err) => err);
};
