import { FormikErrors, FormikTouched, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { CountriesStatesContext, RouterContext } from "shared/Contexts";
import { formatAddressToString } from "shared/helpers/common.helper";
import * as Yup from "yup";

import { AddressDto, LatLngI } from "@interfaces";
import { TextField } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";

import styles from "./GeolocationField.module.scss";

function GeolocationField({
  position,
  toggleValue,
  searchValue,
  errors,
  touched,
}: {
  toggleValue: (e: LatLngI) => void;
  position?: LatLngI;
  searchValue?: AddressDto;
  errors?: FormikErrors<LatLngI>;
  touched?: FormikTouched<LatLngI>;
}) {
  const { countriesStates } = useContext(CountriesStatesContext);
  const [changed, setChanged] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState<LatLngI>({
    lat: 0,
    lng: 0,
  });
  const [routerValue] = useContext(RouterContext);

  const validationSchema = Yup.object().shape({
    lat: Yup.string(),
    lng: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      lat: position?.lat || 0,
      lng: position?.lng || 0,
    } as LatLngI,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    position &&
      setSelectedLocation({
        lat: position.lat,
        lng: position.lng,
      });
  }, [position]);

  useEffect(() => {
    if (searchValue) {
      if (!changed) {
        setChanged(true);
        return;
      }
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address: formatAddressToString(searchValue, countriesStates) },
        (results, status) => {
          if (status === "OK") {
            if (results?.length) {
              setSelectedLocation({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });

              formik.setFieldValue(
                "lat",
                results[0].geometry.location.lat()?.toString()
              );
              formik.setFieldValue(
                "lng",
                results[0].geometry.location.lng()?.toString()
              );
            }
          } else {
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  }, [searchValue]);

  useEffect(() => {
    selectedLocation && toggleValue(selectedLocation);
  }, [selectedLocation]);

  return (
    <>
      {routerValue.loaded && (
        <GoogleMap
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false,
          }}
          onClick={(e) => {
            setSelectedLocation({
              lat: e.latLng?.lat() || 0,
              lng: e.latLng?.lng() || 0,
            });

            formik.setFieldValue("lat", e.latLng?.lat()?.toString());
            formik.setFieldValue("lng", e.latLng?.lng()?.toString());
          }}
          clickableIcons={false}
          mapContainerStyle={{
            width: "100%",
            height: "500px",
          }}
          center={{
            lat: Number(selectedLocation?.lat) || 0,
            lng: Number(selectedLocation?.lng) || 0,
          }}
          zoom={16}
        >
          {selectedLocation && (
            <Marker
              position={{
                lat: Number(selectedLocation.lat),
                lng: Number(selectedLocation.lng),
              }}
            />
          )}
        </GoogleMap>
      )}

      <TextField
        classes={{ root: styles["up-form"] }}
        label="Lat"
        variant="outlined"
        hiddenLabel={true}
        fullWidth={true}
        multiline
        InputProps={{
          classes: { root: styles["up-form-input"] },
        }}
        id="lat"
        name="lat"
        value={formik.values.lat}
        onChange={(e) => {
          formik.handleChange(e);
          setSelectedLocation({ ...selectedLocation, lat: +e.target.value });
        }}
        error={touched?.lat && Boolean(errors?.lat)}
        helperText={touched?.lat && errors?.lat}
      />
      <TextField
        classes={{ root: styles["up-form"] }}
        label="Long"
        variant="outlined"
        hiddenLabel={true}
        fullWidth={true}
        multiline
        InputProps={{
          classes: { root: styles["up-form-input"] },
        }}
        id="lng"
        name="lng"
        value={formik.values.lng}
        onChange={(e) => {
          formik.handleChange(e);
          setSelectedLocation({ ...selectedLocation, lng: +e.target.value });
        }}
        error={touched?.lng && Boolean(errors?.lng)}
        helperText={touched?.lng && errors?.lng}
      />
    </>
  );
}

export default GeolocationField;
