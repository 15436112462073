import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { CheckRoutingPermission } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import AppointmentsCreate from "./Appointments/AppointmentsCreate/AppointmentsCreate";
import AppointmentsEdit from "./Appointments/AppointmentsEdit/AppointmentsEdit";
import ViewCalendar from "./Calendar/ViewCalendar/ViewCalendar";
import RouteStart from "./RouteStart/RouteStart";
import VisitView from "./VisitView/VisitView";

function VisitsRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      <Route path="/calendar" element={<ViewCalendar />} />

      {CheckRoutingPermission(currentUser!) && (
        <>
          <Route path="/location/:type" element={<RouteStart />} />
          <Route path="/location/:type" element={<RouteStart />} />

          <Route path="/:visitId" element={<VisitView />} />
          <Route path="/create" element={<AppointmentsCreate />} />
          <Route path="/:id/edit" element={<AppointmentsEdit />} />

          <Route path={`*`} element={<PageNotFound />} />
        </>
      )}
    </Routes>
  );
}

export default VisitsRouter;
