import { AxiosResponse } from "axios";
import { customAuthApi, helperApi } from "./Settings.api.service";

export const apiLogin = (body: any): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.post(`/user/login?_format=json`, body, {});
};

export const apiLogout = (token: string): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.post(`/user/logout?_format=json&token=${token}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetSessionToken = (): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/session/token`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetLogoutToken = (): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/session/token/logout`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetLoginStatus = (): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/user/login_status?_format=json`);
};

export const apiGetUserInfo = (): Promise<AxiosResponse<any, any>> => {
  return helperApi.get("/user/current");
};

export const apiForgotUserPassword = (body: {
  mail: string;
  login_url: string;
  reset_url: string;
}): Promise<AxiosResponse<any, any>> => {
  return helperApi.post("/user/password/forget", body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiOneTimeLogin = (body: {
  uid: string;
  timestamp: string;
  hash: string;
  new_pass?: string;
}): Promise<AxiosResponse<any, any>> => {
  return helperApi.post("/user/one_time_login", body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetPasswordPolicies = (): Promise<AxiosResponse<any, any>> => {
  return helperApi.get("/password_policy");
};
