import axios, { AxiosError } from "axios";
import Header from "components/Shared/Header/Header";
import BlockLocationSidenav from "components/Shared/Locations/BlockLocations/BlockLocations";
import NotesCards from "components/Shared/Notes/NotesCards/NotesCards";
import TasksCards from "components/Shared/Tasks/TasksCards/TasksCards";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CountriesStatesContext,
  RouterContext,
  UserDataContext,
} from "shared/Contexts";
import {
  AppointmentsType,
  DayScheduleActionsNew,
  DayScheduleStepsStatusesNew,
  PermissionsList,
} from "shared/Enums";
import {
  checkPermissionsForEdit,
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { check12AMto00, checkDate12AMto00 } from "shared/helpers/time.helper";
import { blockLocation, editVisitStatus, getVisitData } from "shared/Services";
import * as Yup from "yup";

import { LocationBlockFormI, VisitDetailsNewI } from "@interfaces";
import {
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";

import CarIcon from "../../../../assets/icons/car.svg";
import PhoneIcon from "../../../../assets/icons/phone.svg";
import TickIcon from "../../../../assets/icons/tick.svg";
import ViewResources from "../Resources/Resources";
import styles from "./VisitView.module.scss";
import { toast } from "react-toastify";
import { UserDataContextI } from "shared/Contexts/UserDataContext";

const moment = require("moment-timezone");

function VisitView() {
  const params = useParams();

  const [visitInfo, setVisitInfo] = useState<VisitDetailsNewI | null>(null);
  const { currentUser } = useContext(UserDataContext);
  const { countriesStates } = useContext(CountriesStatesContext);

  const [routerValue, setRouterValue] = useContext(RouterContext);
  const [openedBlock, setOpenedBlock] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    params.visitId &&
    getVisitData(params.visitId, {
      userIdForTask:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        !isUserHavePermissions(currentUser!, [
          PermissionsList.viewMyServiceTask,
        ]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
          ? currentUser!.uid
          : undefined,
      userIdForNotes:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
        !isUserHavePermissions(currentUser!, [
          PermissionsList.viewMyServiceNote,
        ]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
          ? currentUser!.uid
          : undefined,
    }).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length
          ? data.response?.data?.errors[0]?.detail
          : "");
      } else {
        formik.setFieldValue(
          "isVirtual",
          data.isVirtual ? AppointmentsType.call : AppointmentsType.visit
        );
        setVisitInfo(data);
      }
    });
  };

  const saveBlock = (value?: LocationBlockFormI) => {
    if (value) {
      blockLocation(params.locationId!, value).then(
        (data: AxiosError | any) => {
          if (axios.isAxiosError(data)) {
            toast.error(data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : "");
          } else {
            toast.success("successfully completed!");
            visitInfo &&
            setVisitInfo({
              ...visitInfo,
              location: {
                ...visitInfo.location,
                blocked: value.isBlocked,
                blockedUntil:
                  value.isBlocked && value.isDateSelected
                    ? value.endDate
                    : undefined,
              },
            });
          }
        }
      );
    }
    setOpenedBlock(false);
  };

  const CompleteVisit = () => {
    editVisitStatus(
      params.visitId!,
      DayScheduleActionsNew.completeVisit,
      currentUser!.timezone.value,
      formik.values.isVirtual as AppointmentsType
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length
          ? data.response?.data?.errors[0]?.detail
          : "");
      } else {
        if (visitInfo) {
          setVisitInfo({
            ...visitInfo,
            status: DayScheduleStepsStatusesNew.completed,
          });
        }
      }
    });
  };

  const validationSchema = Yup.object().shape({
    isVirtual: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      isVirtual: AppointmentsType.visit,
    } as {
      isVirtual: string;
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const openMapApp = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes("android");
    const isiOS = /(iphone|ipad|ipod|ios)/i.test(userAgent);

    if (isAndroid) {
      const url = `geo:0,0?q=${visitInfo!.location.geolocation.lat},${
        visitInfo!.location.geolocation.lng
      }`;
      window.location.href = url;
    } else if (isiOS) {
      const url = `maps://?q=${visitInfo!.location.geolocation.lat},${
        visitInfo!.location.geolocation.lng
      }`;
      window.location.href = url;
    } else {
      toast.info("Unsupported device");
    }
  };

  return (
    <>
      <Header
        title={"View Visit"}
        toggleBack={() => navigate(-1)}
        toggleReload={() => getContent()}
        opacity={0}
      />
      {visitInfo && (
        <>
          <div className={styles["up-container"]}>
            <div className={styles["up-card"]}>
              <div className={styles["place-name"]}>
                {visitInfo.location.name}

                {visitInfo.appointment && (
                  <div className={styles["up-card-edit"]}>
                    <Button
                      classes={{ root: styles["up-card-edit-button"] }}
                      color={"inherit"}
                      onClick={() => navigate("edit")}
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </div>

              {!visitInfo.isVirtual ? (
                <div className={styles["travel-time"]}>
                  <img alt="car icon" src={CarIcon} />
                  ETA{" "}
                  {check12AMto00(
                    visitInfo.arrival,
                    currentUser!.timezone.value
                  )}
                </div>
              ) : (
                <div className={styles["call-time"]}>
                  <img alt="phone icon" src={PhoneIcon} />
                  {check12AMto00(
                    visitInfo.arrival,
                    currentUser!.timezone.value
                  )}
                </div>
              )}
              <div className={styles["up-card-footer"]}>
                <div className={styles.address}>
                  {formatAddressToString(
                    visitInfo.location.address,
                    countriesStates
                  )}
                </div>
                {visitInfo.status === DayScheduleStepsStatusesNew.completed && (
                  <div className={styles["status"]}>
                    <img src={TickIcon} alt="tick" />
                    Completed <br />
                    {visitInfo.dateEnd ? (
                      <>
                        {checkDate12AMto00(
                          visitInfo.dateEnd,
                          currentUser!.timezone.value
                        )}
                      </>
                    ) : null}
                  </div>
                )}
              </div>
              {visitInfo.location.blocked &&
                visitInfo.location.blockedUntil && (
                  <div className={styles["up-card-blocked"]}>
                    Temporarily blocked until{" "}
                    {moment
                      .utc(visitInfo.location.blockedUntil)
                      .tz(currentUser!.timezone.value)
                      .format("MMM D, YYYY")}
                  </div>
                )}
            </div>

            {(visitInfo.status === DayScheduleStepsStatusesNew.inProgress ||
              visitInfo.status === DayScheduleStepsStatusesNew.pending) && (
              <div className={styles["up-card-active"]}>
                <div className={styles["up-card-active-radio"]}>
                  <span className={styles["up-card-active-radio-title"]}>
                    Visit was executed as a:
                  </span>
                  <RadioGroup
                    row
                    id="isVirtual"
                    name="isVirtual"
                    value={formik.values.isVirtual}
                    onChange={formik.handleChange}
                    classes={{ root: styles["up-card-active-radio-group"] }}
                  >
                    <FormControlLabel
                      classes={{ label: styles["up-card-active-radio-label"] }}
                      value={AppointmentsType.call}
                      control={<Radio size="small" />}
                      label="Call"
                    />
                    <FormControlLabel
                      classes={{ label: styles["up-card-active-radio-label"] }}
                      value={AppointmentsType.visit}
                      control={<Radio size="small" />}
                      label="In person Visit"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {formik.touched.isVirtual && formik.errors.isVirtual}
                  </FormHelperText>
                </div>

                <Button
                  onClick={CompleteVisit}
                  classes={{ root: styles["up-card-active-button"] }}
                  color={"inherit"}
                >
                  Complete
                </Button>
              </div>
            )}
            <div className={styles["up-buttons"]}>
              {checkPermissionsForEdit(
                currentUser!,
                PermissionsList.editAnyLocation,
                PermissionsList.editOwnLocation,
                visitInfo.location.authorId
              ) && (
                <>
                  {visitInfo.location.blocked ? (
                    <Button
                      classes={{
                        root: `${styles["up-buttons-button"]} ${styles["unskip"]}`,
                      }}
                      onClick={() => setOpenedBlock(true)}
                      color={"inherit"}
                      variant="outlined"
                    >
                      Unblock visits
                    </Button>
                  ) : (
                    <Button
                      classes={{ root: styles["up-buttons-button"] }}
                      onClick={() => setOpenedBlock(true)}
                      color={"inherit"}
                      variant="outlined"
                    >
                      Block visits
                    </Button>
                  )}
                </>
              )}
              {isUserHavePermissions(
                currentUser as UserDataContextI,
                [PermissionsList.createAppointment]
              ) && <Button
                classes={{ root: styles["up-buttons-button"] }}
                color={"inherit"}
                onClick={() => navigate(`/visits/create`)}
                variant="outlined"
              >
                New appointment
              </Button>}

              <Button
                classes={{ root: styles["up-buttons-button"] }}
                color={"inherit"}
                onClick={() => openMapApp()}
                variant="outlined"
              >
                Direction
              </Button>

              <Button
                classes={{ root: styles["up-buttons-button"] }}
                color={"inherit"}
                variant="outlined"
                onClick={() =>
                  setRouterValue({
                    ...routerValue,
                    gMapOpened: true,
                    gMapGeolocation: visitInfo.location.geolocation,
                    gMapLocationName: visitInfo.location.name,
                  })
                }
              >
                Map
              </Button>
            </div>
            <div className={styles["up-content"]}>
              {visitInfo.location.phoneNumbers?.length ? (
                <>
                  <label className={styles["up-content-label"]}>
                    Telephones
                  </label>
                  <div className={styles["up-content-value"]}>
                    {visitInfo.location.phoneNumbers.map((p, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={`tel:${p}`}
                      >
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}

              {visitInfo.location.emails?.length ? (
                <>
                  <label className={styles["up-content-label"]}>Email</label>
                  <div className={styles["up-content-value"]}>
                    {visitInfo.location.emails.map((p, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={`mailto:${p}`}
                      >
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}
              {isUserHavePermissions(currentUser!, [
                PermissionsList.viewAnyContact,
                PermissionsList.viewOwnContact,
              ]) && visitInfo.location.contacts.length ? (
                <>
                  <label className={styles["up-content-label"]}>Contacts</label>
                  <div className={styles["up-content-value"]}>
                    {visitInfo.location.contacts.map((i) => (
                      <div className={styles["up-w-full"]}>
                        <span
                          key={i.id}
                          onClick={() => navigate(`/contacts/${i.id}`)}
                        >
                          {i.name}
                        </span>
                        {i.phoneNumbers?.length ? (
                          <>
                            {i.phoneNumbers.map((p, index) => (
                              <a
                                key={index}
                                rel="noreferrer"
                                target="_blank"
                                href={`tel:${p}`}
                              >
                                {p}
                              </a>
                            ))}
                          </>
                        ) : null}

                        {i.emails?.length ? (
                          <>
                            {i.emails.map((p, index) => (
                              <a
                                key={index}
                                rel="noreferrer"
                                target="_blank"
                                href={`mailto:${p}`}
                              >
                                {p}
                              </a>
                            ))}
                          </>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}

              {isUserHavePermissions(currentUser!, [
                PermissionsList.viewAnyCompany,
                PermissionsList.viewOwnCompany,
              ]) && (
                <>
                  <label className={styles["up-content-label"]}>Company</label>
                  <div className={styles["up-content-value"]}>
                    <span
                      onClick={() =>
                        navigate(`/companies/${visitInfo.location.company.id}`)
                      }
                    >
                      {visitInfo.location.company.name}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>

          {isUserHavePermissions(currentUser!, [
            PermissionsList.viewAnyNote,
            PermissionsList.viewMyServiceNote,
            PermissionsList.viewOwnNote,
          ]) && (
            <div className={styles["up-notes"]}>
              <NotesCards
                ids={
                  visitInfo.status === DayScheduleStepsStatusesNew.pending
                    ? { visitId: visitInfo.id }
                    : { locationId: visitInfo.location.id }
                }
                content={visitInfo.location.notes}
              ></NotesCards>
            </div>
          )}
          {isUserHavePermissions(currentUser!, [
            PermissionsList.viewAnyTask,
            PermissionsList.viewOwnTask,
            PermissionsList.viewMyServiceTask,
          ]) && (
            <div className={styles["up-tasks"]}>
              <TasksCards location={visitInfo.location}></TasksCards>
            </div>
          )}

          <div className={styles["up-resources"]}>
            <ViewResources
              content={visitInfo.resources}
              visitId={params.visitId!}
              canAdd={visitInfo.status === DayScheduleStepsStatusesNew.pending ||
                visitInfo.status === DayScheduleStepsStatusesNew.inProgress}
            ></ViewResources>
          </div>

          <BlockLocationSidenav
            opened={openedBlock}
            onToggle={(e) => saveBlock(e)}
            locationName={visitInfo.location.name}
            blocked={visitInfo.location.blocked}
            blockedUntil={visitInfo.location.blockedUntil}
          />
        </>
      )}
    </>
  );
}

export default VisitView;
