import { AxiosResponse } from "axios";

import { helperApi, jsonAuthApi } from "./Settings.api.service";

export const getLocationsPriority = (
  ids: string[]
): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`/entity/location_priority`, {
    params: {
      uuids: ids,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetCountriesAndStates = (): Promise<
  AxiosResponse<any, any>
> => {
  return helperApi.get(`/address/countries_states`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetProductsList = (
  page: number
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_product/ups_product`, {
    params: {
      "page[limit]": 5,
      "page[offset]": page,
      "filter[status][value]": "1",
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetSettingsList = (): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`/settings`, {
    params: {},
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetUsersList = (
  page: number,
  userId: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/profile/agent`, {
    params: {
      include: "uid",
      "page[limit]": 10,
      "page[offset]": page,
      "filter[field_manager.id][operator]": "=",
      "filter[field_manager.id][value]": userId,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
