import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import LocationsList from "components/Shared/Locations/LocationsList/LocationsList";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { NotePublishType, PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import {
  createNote,
  getLocationName,
  getTaskName,
  getTasksNameIdList,
  getVisitName,
  getVisitsNameIdList,
} from "shared/Services";
import * as Yup from "yup";

import { NoteFormI } from "@interfaces";
import {
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import styles from "./NoteCreate.module.scss";
import { toast } from "react-toastify";

const moment = require("moment-timezone");

function NoteCreate() {
  const { currentUser } = useContext(UserDataContext);

  const [locationsOpened, setLocationsOpened] = useState(false);
  const [tasksOpened, setTasksOpened] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();
  const params = useParams();

  const [tasksList, setTasksList] = useState<
    { id: string; title: string; locationId: string; status: any }[]
  >([]);

  const [visitsOpened, setVisitsOpened] = useState(false);

  const [visitsHasMore, setVisitsHasMore] = useState(true);
  const [visitPageNumber, setVisitPageNumber] = useState(0);

  const [visitsList, setVisitsList] = useState<
    { id: string; title: string; locationId: string; status: any }[]
  >([]);

  const [publishSelected, setPublishSelected] = useState<{
    selectedType: NotePublishType;
    item: null | any;
  }>({
    selectedType: NotePublishType.location,
    item: null,
  });

  function getValidationSchema(values: NoteFormI) {
    if (publishSelected.selectedType === NotePublishType.task) {
      return Yup.object().shape({
        note: Yup.string().required("This field is required"),
        task: Yup.string().required("This field is required"),
        visit: Yup.string(),
        location: Yup.string(),
        publishType: Yup.string(),
      });
    } else if (publishSelected.selectedType === NotePublishType.visit) {
      return Yup.object().shape({
        note: Yup.string().required("This field is required"),
        task: Yup.string(),
        visit: Yup.string().required("This field is required"),
        location: Yup.string(),
        publishType: Yup.string(),
      });
    } else {
      return Yup.object().shape({
        note: Yup.string().required("This field is required"),
        task: Yup.string(),
        visit: Yup.string(),
        location: Yup.string().required("This field is required"),
        publishType: Yup.string(),
      });
    }
  }

  const formik = useFormik({
    initialValues: {
      note: "",
      location: "",
      task: "",
      visit: "",
      publishType: NotePublishType.location,
    } as NoteFormI,
    validationSchema: getValidationSchema,
    onSubmit: (values) => {
      createNote({ ...values, ...publishSelected }).then(
        (data: AxiosError | any) => {
          if (axios.isAxiosError(data)) {
            toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
          } else {
            toast.success("successfully completed!");
            navigate(-1);
            //navigate(`/notes/${data}`);
          }
        }
      );
    },
  });
  useEffect(() => {
    getTasksNameIdList(pageNumber * 20, currentUser!.timezone.value, {
      id:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [
          PermissionsList.viewMyServiceTask,
        ]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
          ? currentUser!.uid
          : undefined,
      serviceId:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask])
          ? currentUser!.uid
          : undefined,
    }).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        const updatedTasksList = tasksList;
        data.items.forEach((item: {
          id: string;
          title: string;
          locationId: string;
          status: any
        }) => {
          if (tasksList.length > 0) {
            tasksList.forEach(task => {
              if (task.id !== item.id) updatedTasksList.push(item);
            });
          } else updatedTasksList.push(item);
        });
        setTasksList(updatedTasksList);
        setHasMore(data.hasMore);
      }
    });
  }, [pageNumber]);

  useEffect(() => {
    getVisitsNameIdList(
      visitPageNumber * 20,
      currentUser!.timezone.value,
      currentUser!.uid
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setVisitsList([...visitsList, ...data.items]);
        setVisitsHasMore(data.hasMore);
      }
    });
  }, [visitPageNumber]);

  useEffect(() => {
    params.locationId &&
    getLocationName(params.locationId).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setPublishSelected({
          selectedType: NotePublishType.location,
          item: data,
        });

        formik.setFieldValue("location", data.id);
        formik.setFieldValue("publishType", NotePublishType.location);
      }
    });
    params.taskId &&
    getTaskName(params.taskId).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setPublishSelected({
          selectedType: NotePublishType.task,
          item: data,
        });
        formik.setFieldValue("task", data.id);
        formik.setFieldValue("publishType", NotePublishType.task);
        formik.setFieldValue("location", data.location);
      }
    });
    params.visitId &&
    getVisitName(params.visitId).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setPublishSelected({
          selectedType: NotePublishType.visit,
          item: data,
        });
        formik.setFieldValue("publishType", NotePublishType.visit);
        formik.setFieldValue("visit", data.id);
      }
    });
  }, []);

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Create note"
        onRightBtnToggle={() => formik.handleSubmit()}
        position={"absolute"}
        background="transparent"
        onCancelToggle={() => navigate(-1)}
      />
      <>
        <RadioGroup
          row
          id="publishType"
          name="publishType"
          value={formik.values.publishType}
          onChange={(e) => {
            formik.setFieldValue("location", "");
            formik.setFieldValue("task", "");
            setPublishSelected({
              selectedType: e.target.value as NotePublishType,
              item: null,
            });
            formik.handleChange(e);
          }}
          classes={{ root: styles["up-radio-group"] }}
        >
          <FormControlLabel
            classes={{ root: styles["up-radio-label"] }}
            value={NotePublishType.location}
            control={<Radio />}
            label="Location"
          />
          <FormControlLabel
            classes={{ root: styles["up-radio-label"] }}
            value={NotePublishType.visit}
            control={<Radio />}
            label="Visit"
          />
          <FormControlLabel
            classes={{ root: styles["up-radio-label"] }}
            value={NotePublishType.task}
            control={<Radio />}
            label="Task"
          />
        </RadioGroup>
        <div className={styles["up-publish"]}>
          {publishSelected.selectedType === NotePublishType.location && (
            <>
              <TextField
                size="medium"
                classes={{ root: styles["up-form-location"] }}
                label="Location"
                variant="outlined"
                hiddenLabel={true}
                fullWidth={true}
                multiline
                onClick={() => setLocationsOpened(true)}
                InputProps={{
                  classes: { root: styles["up-form-location-input"] },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                id="location"
                name="location"
                value={
                  publishSelected.selectedType === NotePublishType.location
                    ? publishSelected.item?.title
                    : ""
                }
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
              />
              <Modal open={locationsOpened} onClose={() => {}}>
                <div className={styles["up-modal"]}>
                  <FormsHeader
                    title="Pick Location"
                    position="relative"
                    onCancelToggle={() => setLocationsOpened(false)}
                    onRightBtnToggle={() => setLocationsOpened(false)}
                  />

                  <div className={styles["up-modal-content"]}>
                    <LocationsList
                      hasSearch={true}
                      active={
                        publishSelected.selectedType ===
                        NotePublishType.location
                          ? publishSelected.item?.id
                          : undefined
                      }
                      locationSelected={(location) => {
                        formik.setFieldValue("location", location.id);
                        setPublishSelected({
                          selectedType: NotePublishType.location,
                          item: location,
                        });
                        setLocationsOpened(false);
                      }}
                    />
                  </div>
                </div>
              </Modal>
            </>
          )}
          {publishSelected.selectedType === NotePublishType.task && (
            <>
              <TextField
                size="medium"
                classes={{ root: styles["up-form-task"] }}
                label="Task"
                variant="outlined"
                hiddenLabel={true}
                fullWidth={true}
                multiline
                onClick={() => setTasksOpened(true)}
                InputProps={{
                  classes: { root: styles["up-form-task-input"] },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                id="task"
                name="task"
                value={
                  publishSelected.selectedType === NotePublishType.task
                    ? publishSelected.item?.name
                    : ""
                }
                error={formik.touched.task && Boolean(formik.errors.task)}
                helperText={formik.touched.task && formik.errors.task}
              />
              <Modal open={tasksOpened} onClose={() => {}}>
                <div className={styles["up-modal"]}>
                  <FormsHeader
                    title="Pick Task"
                    position="relative"
                    onCancelToggle={() => setTasksOpened(false)}
                    onRightBtnToggle={() => setTasksOpened(false)}
                  />

                  <InfiniteScroll
                    dataLength={tasksList.length}
                    next={() => setPageNumber(pageNumber + 1)}
                    hasMore={hasMore}
                    height={"calc(100vh - 50px)"}
                    loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
                  >
                    {tasksList.length ? (
                      tasksList.map((item) => (
                        <div
                          key={item.id}
                          className={`${styles["up-publish-tasks"]} ${
                            publishSelected.selectedType ===
                            NotePublishType.task &&
                            publishSelected.item?.id === item.id
                              ? styles["active"]
                              : ""
                          }`}
                          onClick={() => {
                            formik.setFieldValue("task", item.id);
                            formik.setFieldValue("location", item.locationId);
                            setPublishSelected({
                              selectedType: NotePublishType.task,
                              item: item,
                            });
                            setTasksOpened(false);
                          }}
                        >
                          {item.title}
                        </div>
                      ))
                    ) : (
                      <div className="up-no-items">
                        No active task records found
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              </Modal>
            </>
          )}
          {publishSelected.selectedType === NotePublishType.visit && (
            <>
              <TextField
                size="medium"
                classes={{ root: styles["up-form-visit"] }}
                label="Visit"
                variant="outlined"
                hiddenLabel={true}
                fullWidth={true}
                multiline
                onClick={() => setVisitsOpened(true)}
                InputProps={{
                  classes: { root: styles["up-form-visit-input"] },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                id="visit"
                name="visit"
                value={
                  publishSelected.selectedType === NotePublishType.visit
                    ? publishSelected.item?.title
                    : ""
                }
                error={formik.touched.visit && Boolean(formik.errors.visit)}
                helperText={formik.touched.visit && formik.errors.visit}
              />
              <Modal open={visitsOpened} onClose={() => {}}>
                <div className={styles["up-modal"]}>
                  <FormsHeader
                    title="Pick Visit"
                    position="relative"
                    onCancelToggle={() => setVisitsOpened(false)}
                    onRightBtnToggle={() => setVisitsOpened(false)}
                  />

                  <InfiniteScroll
                    dataLength={visitsList.length}
                    next={() => setPageNumber(visitPageNumber + 1)}
                    hasMore={visitsHasMore}
                    height={"calc(100vh - 50px)"}
                    loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
                  >
                    {visitsList.length ? (
                      visitsList.map((item) => (
                        <div
                          key={item.id}
                          className={`${styles["up-publish-visits"]} ${
                            publishSelected.selectedType ===
                            NotePublishType.visit &&
                            publishSelected.item?.id === item.id
                              ? styles["active"]
                              : ""
                          }`}
                          onClick={() => {
                            formik.setFieldValue("visit", item.id);
                            formik.setFieldValue("location", item.locationId);
                            setPublishSelected({
                              selectedType: NotePublishType.visit,
                              item: item,
                            });
                            setVisitsOpened(false);
                          }}
                        >
                          {item.title}
                        </div>
                      ))
                    ) : (
                      <div className="up-no-items">
                        No active visit records found
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              </Modal>
            </>
          )}
        </div>
        <TextField
          classes={{ root: styles["up-form-note"] }}
          placeholder="note"
          variant="outlined"
          hiddenLabel={true}
          fullWidth={true}
          multiline
          minRows={5}
          InputProps={{
            classes: { root: styles["up-form-note-input"] },
          }}
          id="note"
          name="note"
          value={formik.values.note}
          onChange={formik.handleChange}
          error={formik.touched.note && Boolean(formik.errors.note)}
          helperText={formik.touched.note && formik.errors.note}
        />
      </>
    </div>
  );
}

export default NoteCreate;
