import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderContext, ManagerContext, UserDataContext } from "shared/Contexts";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ChangeUserIcon from "../../../shared/Icons/ChangeUserIcon";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, IconButton, useScrollTrigger } from "@mui/material";

import styles from "./Header.module.scss";
import { Roles } from "../../../shared/Enums";
import { AgentDialog } from "../Agents/AgentDialog/AgentDialog";

interface HeaderProps {
  openDrawer?: (value: boolean) => void;
  openAddDrawer?: (value: boolean) => void;
  toggleBack: () => void;
  toggleReload?: () => void;
  opacity?: number;
  title?: string | ReactNode;
  isAgentMode?: boolean;
  hideBack?: boolean;
  hideReload?: boolean;
}

function Header({
                  title,
                  toggleBack,
                  opacity = 1,
                  hideBack,
                  toggleReload,
                  hideReload = false,
                }: HeaderProps) {
  const navigate = useNavigate();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [, setHeaderContext] = useContext(HeaderContext);
  const { currentUser } = useContext(UserDataContext);
  const [{ agent }, setManagerContext] = useContext(ManagerContext);

  const [headerColor, setHeaderColor] = useState("#fff");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setHeaderColor(trigger ? "#fff" : `rgba(255,255,255,${opacity})`);
  }, [trigger, opacity]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      classes={{ root: styles["up-header"] }}
      style={{ backgroundColor: headerColor }}
    >
      {!hideBack ? (
        <IconButton color="inherit" aria-label="back" onClick={toggleBack} classes={{root: styles['up-header--back-btn']}}>
          <ArrowBackIosNewIcon fontSize={"small"} />
          <span className={styles["up-header--back-btn-txt"]}>Back</span>
        </IconButton>
      ) : (
        <div></div>
      )}
      <div className={styles["up-header-title"]}>
        {title}

        {agent && <IconButton
          color="inherit"
          aria-label="exit-agents"
          onClick={(e) => setIsDialogOpen(true)}
        >
          <ExitToAppIcon />
        </IconButton>
        }

        <AgentDialog
          open={isDialogOpen}
          onClose={handleClose}
          onSubmit={() => {
            setManagerContext({ agent: null });
            setIsDialogOpen(false);
          }} />
      </div>
      <div className={styles["up-header-icons"]}>
        {currentUser?.roles.includes(Roles.manager) && <IconButton
          color="inherit"
          aria-label="agents"
          onClick={() => navigate("/agents")}
        >
          <ChangeUserIcon />
        </IconButton>}

        {!hideReload && (
          <IconButton
            color="inherit"
            aria-label="refresh"
            onClick={toggleReload}
          >
            <AutorenewIcon />
          </IconButton>
        )}

        {!currentUser?.roles.includes(Roles.manager) && <IconButton
          color="inherit"
          aria-label="add"
          onClick={() =>
            setHeaderContext({
              opened: false,
              addOpened: true,
            })
          }
        >
          <AddIcon />
        </IconButton>}

        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() =>
            setHeaderContext({
              opened: true,
              addOpened: false,
            })
          }
        >
          <MenuIcon />
        </IconButton>
      </div>
    </AppBar>
  );
}

export default Header;
