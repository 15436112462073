export const TimeOptionGroups = {
  hour: [...Array(13)].map((_, index) => ({
    value: index < 10 ? "0" + index : index.toString(),
    label: index < 10 ? "0" + index : index.toString(),
  })),
  minute: [...Array(12)].map((_, index) => ({
    value: index < 2 ? "0" + index * 5 : (index * 5).toString(),
    label: index < 2 ? "0" + index * 5 : (index * 5).toString(),
  })),
  period: [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
  ],
};
