import { Link } from "@mui/material";
import Header from "../../../Shared/Header/Header";
import { useNavigate } from "react-router-dom";
import styles from "./ViewReports.module.scss";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function ViewReports() {
  const navigate = useNavigate();

  return <div className={styles["up-container"]}>
    <Header
      title={"Reports"}
      toggleBack={() => navigate("/")}
      hideReload={true}
      opacity={0}
    />

    <div className={styles["up-link-wrapper"]}>
      <Link
        href={`${baseUrl}/admin/reports/ups-reports/performance`}
        target="_blank"
        underline="hover"
      >
        Performance
      </Link>
      <Link
        href={`${baseUrl}/admin/reports/ups-reports/locations`}
        target="_blank"
        underline="hover"
      >
        Locations
      </Link>
      <Link
        href={`${baseUrl}/admin/reports/ups-reports/tasks`}
        target="_blank"
        underline="hover"
      >
        Tasks
      </Link>
    </div>
  </div>;
}
