import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import styles from "../AgentDialog/AgentDialog.module.scss";
import { SyntheticEvent } from "react";

export function AgentDialog({
                              open,
                              name,
                              onClose,
                              onSubmit,
                            }: {
  open: boolean,
  name?: string,
  onClose: (e: SyntheticEvent) => void,
  onSubmit: () => void,
}) {
  return <Dialog
    className={"up-agents-dialog-container"}
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle
      id="alert-dialog-title"
      classes={{ root: styles["up-agents-dialog-title"] }}
    >
      {name
        ? <div>
          You are switching to <span className={styles["up-agents-dialog-full-name"]}>{name}'s</span> view.
        </div>
        : <div> You are now switching back to <span className={styles["up-agents-dialog-full-name"]}>
           your account</span>.
        </div>
      }
    </DialogTitle>

    <DialogActions classes={{ root: styles["up-agents-dialog-actions"] }}>
      <Button
        classes={{ root: styles["up-agents-dialog-actions--cancel-btn"] }}
        onClick={onClose}
      >
        Cancel
      </Button>

      <Button
        classes={{ root: styles["up-agents-dialog-actions--ok-btn"] }}
        onClick={onSubmit}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>;
}
