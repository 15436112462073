import axios from "axios";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ResourceLocationStatusEnum } from "shared/Enums";
import { deleteVisitResource, UploadVisitResources } from "shared/Services";

import { ResourceI, ResourcesListI } from "@interfaces";
import { PhotoCamera } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import PublicIcon from "@mui/icons-material/Public";
import { Chip, Dialog, IconButton, Link, SwipeableDrawer } from "@mui/material";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import styles from "./Resources.module.scss";
import { toast } from "react-toastify";

function ViewResources({
  content,
  visitId,
  canAdd = false,
  scrollData,
}: {
  content: ResourcesListI;
  visitId?: string;
  canAdd: boolean;
  scrollData?: {
    items: ResourceI[];
    setPageNumber: (action: number) => void;
    pageNumber: number;
    hasMore: boolean;
  };
}) {
  const [openedImageSrc, setOpenedImageSrc] = useState<null | ResourceI>(null);

  const [items, setItems] = useState<ResourcesListI>({
    count: 0,
    items: [],
  });

  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    setItems(content);
  }, [content]);

  const deleteResource = (fileId: string) => {
    deleteVisitResource(
      fileId,
      visitId!,
      items.items.filter((i) => i.id !== fileId).map((i) => i.id)
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setItems({
          count: items.count - 1,
          items: items.items.filter((i) => i.id !== fileId),
        });
        setOpenedImageSrc(null);
        toast.success("successfully deleted!");
      }
    });
  };
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          const result = event.target?.result;
          if (result) {
            UploadVisitResources(visitId!, reader.result, file.name).then(
              (data) => {
                if (axios.isAxiosError(data)) {
                  toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
                } else {
                  setItems(data);
                }
              }
            );
          }
        };
        reader.onerror = (event: ProgressEvent<FileReader>) => {
          console.error("Error reading image:", event.target?.error);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-header"]}>
        <h1 className={styles["up-header-title"]}>Resources</h1>
        <Chip
          label={items.count}
          classes={{
            root: styles["up-header-chip"],
            label: styles["up-header-chip-label"],
          }}
        />
        {scrollData && content.items.length > 0 && (
          <Link
            onClick={() => setOpened(true)}
            underline="none"
            classes={{ root: styles["up-header-link"] }}
          >
            All Resources
          </Link>
        )}
        {canAdd && visitId && (
          <>
            <IconButton
              color="inherit"
              aria-label="upload picture"
              component="label"
              classes={{ root: styles["up-header-camera"] }}
            >
              <label htmlFor="camera-upload">
                <input
                  type="file"
                  onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                  id="camera-upload"
                  accept="image/*"
                  hidden
                  capture
                  onChange={onSelectFile}
                />
                <PhotoCamera color={"inherit"} fontSize={"small"} />
              </label>
            </IconButton>

            <IconButton
              color="inherit"
              classes={{ root: styles["up-header-add"] }}
            >
              <label htmlFor="library-upload">
                <input
                  type="file"
                  onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                  id="library-upload"
                  accept="image/*"
                  hidden
                  onChange={onSelectFile}
                />
                <AddIcon color={"inherit"} fontSize={"small"} />
              </label>
            </IconButton>
          </>
        )}
      </div>

      {items.items?.length ? (
        <div className={styles["up-grid"]}>
          {items.items.map((i) => (
            <div key={i.id} className={styles["up-grid-item"]}>
              <img
                src={process.env.REACT_APP_IMAGES_URL! + i.mediumSrc}
                onClick={() => setOpenedImageSrc(i)}
                alt={"resource"}
                loading="lazy"
              />
              <div className={styles["up-grid-item-bottom"]}>
                <>
                  <PublicIcon
                    className={`${styles["up-grid-item-bottom-icon"]} ${
                      i.geoStatus === ResourceLocationStatusEnum.unknown
                        ? styles["grey"]
                        : i.geoStatus === ResourceLocationStatusEnum.ok
                        ? styles["green"]
                        : styles["red"]
                    }`}
                  />
                  <AccessTimeIcon
                    className={`${styles["up-grid-item-bottom-icon"]} ${
                      i.timeStatus === ResourceLocationStatusEnum.unknown
                        ? styles["grey"]
                        : i.timeStatus === ResourceLocationStatusEnum.ok
                        ? styles["green"]
                        : styles["red"]
                    }`}
                  />
                </>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className={styles["up-grid-no-items"]}>No resources yet.</p>
      )}
      <Dialog
        open={openedImageSrc !== null}
        onClose={() => {
          setOpenedImageSrc(null);
        }}
      >
        {openedImageSrc && (
          <div className={styles["up-modal"]}>
            {canAdd && visitId && (
              <IconButton
                onClick={() => deleteResource(openedImageSrc.id)}
                className={styles["up-modal-delete"]}
              >
                <img src={DeleteIcon} alt="Delete" />
              </IconButton>
            )}
            <img
              className={styles["up-modal-img"]}
              src={process.env.REACT_APP_IMAGES_URL! + openedImageSrc.src || ""}
              alt="resource"
            />
          </div>
        )}
      </Dialog>

      {scrollData && (
        <SwipeableDrawer
          open={opened}
          onClose={() => setOpened(false)}
          onOpen={() => setOpened(true)}
          anchor="bottom"
          swipeAreaWidth={44}
          disableSwipeToOpen={true}
          classes={{ paper: styles["up-drawer"] }}
        >
          <div className={styles["up-puller"]} />
          <div className={styles["up-swipeable"]}>
            <div
              className={styles["up-swipeable-grid"]}
              id="resources-scrollable"
            >
              <InfiniteScroll
                style={{
                  overflow: "hidden",
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                }}
                dataLength={scrollData.items.length}
                next={() => {
                  scrollData.setPageNumber(scrollData.pageNumber + 1);
                }}
                hasMore={scrollData.hasMore}
                scrollableTarget={"resources-scrollable"}
                loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
              >
                {scrollData.items.map((i) => (
                  <div key={i.id} className={styles["up-swipeable-item"]}>
                    <img
                      src={process.env.REACT_APP_IMAGES_URL! + i.mediumSrc}
                      onClick={() => setOpenedImageSrc(i)}
                      alt={"resource"}
                      loading="lazy"
                    />
                    <div className={styles["up-swipeable-bottom"]}>
                      <>
                        <PublicIcon
                          className={`${styles["up-swipeable-bottom-icon"]} ${
                            i.geoStatus === ResourceLocationStatusEnum.unknown
                              ? styles["grey"]
                              : i.geoStatus === ResourceLocationStatusEnum.ok
                              ? styles["green"]
                              : styles["red"]
                          }`}
                        />
                        <AccessTimeIcon
                          className={`${styles["up-swipeable-bottom-icon"]} ${
                            i.timeStatus === ResourceLocationStatusEnum.unknown
                              ? styles["grey"]
                              : i.timeStatus === ResourceLocationStatusEnum.ok
                              ? styles["green"]
                              : styles["red"]
                          }`}
                        />
                      </>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </SwipeableDrawer>
      )}
    </div>
  );
}

export default ViewResources;
