import { WorkingHoursItemI } from "@interfaces";

export const WorkingBreakMocked: WorkingHoursItemI[] = [
  {
    from: "01:00 PM",
    to: "02:00 PM",
    checked: true,
    day: 8,
    name: "Break",
  },
];

export const WorkingHoursMocked: WorkingHoursItemI[] = [
  {
    from: "09:00 AM",
    to: "6:00 PM",
    checked: true,
    day: 0,
  },
  {
    from: "09:00 AM",
    to: "6:00 PM",
    checked: true,
    day: 1,
  },
  {
    from: "09:00 AM",
    to: "6:00 PM",
    checked: true,
    day: 2,
  },
  {
    from: "09:00 AM",
    to: "6:00 PM",
    checked: true,
    day: 3,
  },
  {
    from: "09:00 AM",
    to: "6:00 PM",
    checked: true,
    day: 4,
  },
  {
    from: "09:00 AM",
    to: "6:00 PM",
    checked: true,
    day: 5,
  },
  {
    from: "09:00 AM",
    to: "6:00 PM",
    checked: true,
    day: 6,
  },
  ...WorkingBreakMocked,
];
