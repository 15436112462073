import styles from "./PasswordChangeForm.module.scss";
import { Button, TextField } from "@mui/material";
import { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "shared/Services/Profile.service";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts/UserDataContext";
import PasswordPolicies from "../../PasswordPolicies/PasswordPolicies";

export default function PasswordChangeForm() {
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(UserDataContext);

  const logoutHandler = () => {
    localStorage.removeItem("csrf_token");
    localStorage.removeItem("current_user");
    localStorage.removeItem("session_token");
    setCurrentUser(null);
    navigate("/");
  };

  const validationSchema = Yup.object().shape({
    newPass: Yup
      .string()
      .required("New password is required"),
    confirmPass: Yup
      .string()
      .oneOf([Yup.ref("newPass"), null], "Passwords must match")
      .required("Password confirmation is required"),
  });

  const formik = useFormik({
    initialValues: {
      currentPass: "",
      newPass: "",
      confirmPass: ""
    },
    validationSchema,
    onSubmit: (values) => {
      changePassword(values).then((resp: AxiosError | any) => {
        if (axios.isAxiosError(resp)) {
          toast.error(resp?.message || "Request failed.");
        } else {
          if (resp.data.error) {
            const messages = resp.data.error.split("<br/>");
            const markup = messages.length > 1 ? <ul className={styles["validation-error"]}>
              {messages.map((item: any, i: number) => <li key={i}>{item}</li>)}
            </ul> : messages[0];

            toast.error(markup || "Password validation error.");
          } else {
            toast.success("Password changed!");
            logoutHandler();
          }
        }
      });
    },
  });

  return <div className={styles["up-container"]}>
    <form className={styles["up-form"]} onSubmit={formik.handleSubmit}>

      <TextField
        id="current-password"
        name="currentPass"
        label="Current password"
        classes={{
          root: styles["up-form-textfield"]
        }}
        InputProps={{
          classes: {
            notchedOutline: styles["up-form-notched-outlined"]
          }
        }}
        value={formik.values.currentPass}
        onChange={formik.handleChange}
      />

      <TextField
        id="new-password"
        name="newPass"
        label="New password"
        classes={{
          root: styles["up-form-textfield"]
        }}
        InputProps={{
          classes: {
            notchedOutline: styles["up-form-notched-outlined"]
          }
        }}
        value={formik.values.newPass}
        onChange={formik.handleChange}
      />

      <TextField
        id="confirm-password"
        name="confirmPass"
        label="Confirm new password"
        classes={{
          root: styles["up-form-textfield"]
        }}
        InputProps={{
          classes: {
            notchedOutline: styles["up-form-notched-outlined"]
          }
        }}
        value={formik.values.confirmPass}
        onChange={formik.handleChange}
        error={formik.touched.confirmPass && Boolean(formik.errors.confirmPass)}
        helperText={formik.touched.confirmPass && formik.errors.confirmPass}
      />

      <PasswordPolicies/>

      <Button
        type="submit"
        color={"inherit"}
        classes={{ root: styles["up-form-button"] }}
        variant="outlined"
      >
        SAVE
      </Button>
    </form>
  </div>;
};
