import { AxiosResponse } from "axios";

import { helperApi, jsonAuthApi } from "./Settings.api.service";

export const apiGetNotesCount = (
  uuids: string[]
): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`/entity/location_note`, {
    params: {
      uuids,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetNotesListByLocation = (
  locationId: string,
  userId?: string
): Promise<AxiosResponse<any, any>> => {
  const idFilter = userId
    ? {
        "filter[uid.id][value]": userId,
      }
    : {};

  return jsonAuthApi.get(`/ups_note/ups_note`, {
    params: {
      "page[limit]": 10,
      "page[offset]": 0,
      "filter[status][value]": "1",
      "filter[location.id][operator]": "=",
      "filter[location.id][value]": locationId,
      include: "uid",
      ...idFilter,
    },
  });
};
export const apiGetNotesListByTask = (
  taskId: string,
  userId?: string
): Promise<AxiosResponse<any, any>> => {
  const idFilter = userId
    ? {
        "filter[uid.id][value]": userId,
      }
    : {};
  return jsonAuthApi.get(`/ups_note/ups_note`, {
    params: {
      "page[limit]": 10,
      "page[offset]": 0,
      "filter[status][value]": "1",
      "filter[task.id][operator]": "=",
      "filter[task.id][value]": taskId,
      include: "uid",
      ...idFilter,
    },
  });
};

export const apiCreateNote = (body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/ups_note/ups_note/`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetNotesList = (
  pageNumber: number,
  searchText: string,
  params?: {
    locationId?: string;
    userId?: string;
    serviceId?: string;
  }
): Promise<AxiosResponse<any, any>> => {
  const idFilter = params?.userId
    ? {
        "filter[uid.id][value]": params.userId,
      }
    : {};
  const locationParams = params?.locationId
    ? {
        "filter[location.id][operator]": "=",
        "filter[location.id][value]": params.locationId,
      }
    : {};
  const serviceIdFilter = params?.serviceId
    ? {
        "filter[location.agent.id][operator]": "=",
        "filter[location.agent.id][value]": params?.serviceId,
      }
    : {};

  return jsonAuthApi.get(`/ups_note/ups_note`, {
    params: {
      "page[limit]": 20,
      "page[offset]": pageNumber,
      "filter[status][value]": "1",
      ...locationParams,
      include: "uid,task,location",
      ...idFilter,
      ...serviceIdFilter,
      "filter[search-group][group][conjunction]": "OR",
      "filter[note.value][operator]": "CONTAINS",
      "filter[note.value][value]": searchText || "",
      "filter[note.value][condition][memberOf]": "search-group",
      "filter[task.label][operator]": "CONTAINS",
      "filter[task.label][value]": searchText || "",
      "filter[task.label][condition][memberOf]": "search-group",
      "filter[location.label][operator]": "CONTAINS",
      "filter[location.label][value]": searchText || "",
      "filter[location.label][condition][memberOf]": "search-group",
    },
  });
};
export const apiGetNoteData = (
  id: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi
    .get(`/ups_note/ups_note/${id}`, {
      params: {
        include: "location,task,uid,visit",
      },
    })
    .catch((err) => err);
};
export const apiEditNoteData = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_note/ups_note/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiDeleteNote = (
  id: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.delete(`/ups_note/ups_note/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
