const moment = require("moment-timezone");

import { WorkingHoursItemI } from "@interfaces";

export const WorkingHoursFormat = (date: WorkingHoursItemI): string => {
  return `${WeekDays[date.day]}: ${date.from}-${date.to}`;
};

export const WeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const WeekShortDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const MessengersKeys = [
  "https://wa.me/",
  "https://m.me/",
  "https://t.me/",
];

export const formatTimeStringToDate = (
  date: string,
  timezone: string
): string => {
  return moment.tz(date, "hh:mm A", timezone).format("YYYY-MM-DDTHH:mm:ssZZ");
};

/**
 * Generates the color based on the name of the agent.
 * @param str
 */
function stringToColor(str: string) {
  let hash = 0;
  let i;

  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

/**
 * Generates initials and background color.
 * @param name
 */
export function stringAvatar(name: string) {
  const splitted = name.split(' ');
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: splitted.length > 1 ? `${splitted[0][0]}${splitted[1][0]}` : `${splitted[0][0]}`,
  };
}
