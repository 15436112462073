import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import TasksForm from "components/Shared/Tasks/TasksForm/TasksForm";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { checkPermissionsForEdit } from "shared/helpers/common.helper";
import { deleteTask, editTaskData, getTaskData } from "shared/Services";

import { TaskCreateFormI, TaskDetailsI } from "@interfaces";

import styles from "./TaskEdit.module.scss";
import { toast } from "react-toastify";

function TaskEdit() {
  const { currentUser } = useContext(UserDataContext);
  const [taskInfo, setTaskInfo] = useState<TaskDetailsI | null>(null);

  const [submitted, setSubmitted] = useState(0);
  const params = useParams();
  const navigate = useNavigate();

  const FormSubmit = (value: TaskCreateFormI) => {
    editTaskData(params.taskId!, value).then((data: AxiosError | any) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        toast.success("successfully completed!");

        if (data.data.validation) {
          toast.warning(data.data.validation.message);
        }
        navigate(`/tasks/${params.taskId}`);
      }
    });
  };

  useEffect(() => {
    params.taskId &&
      getTaskData(params.taskId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
        } else {
          setTaskInfo(data);
        }
      });
  }, []);

  const ToggleDelete = () => {
    params.taskId && deleteTask(params.taskId).then((data: AxiosError | any) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        toast.success("successfully deleted!");
        navigate(`/tasks`);
      }
    });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Edit task"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        viewDeleteButton={checkPermissionsForEdit(
          currentUser!,
          PermissionsList.deleteAnyTask,
          PermissionsList.deleteOwnTask,
          taskInfo?.authorId || ""
        )}
        deleteToggle={() => ToggleDelete()}
        onCancelToggle={() => navigate(-1)}
      />
      {taskInfo && (
        <TasksForm
          formValue={taskInfo}
          submitted={submitted}
          formFinished={FormSubmit}
        ></TasksForm>
      )}
    </div>
  );
}

export default TaskEdit;
