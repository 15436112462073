import axios, { AxiosError } from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import NotesCards from "components/Shared/Notes/NotesCards/NotesCards";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CountriesStatesContext,
  UserDataContext,
} from "shared/Contexts";
import { PermissionsList, TaskPriority, TaskStatuses } from "shared/Enums";
import {
  checkPermissionsForEdit,
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { checkDate12AMto00 } from "shared/helpers/time.helper";
import { completeTask, getTaskViewData } from "shared/Services";

import { TaskDetailsI } from "@interfaces";
import { Button } from "@mui/material";

import TickIcon from "../../../../assets/icons/tick.svg";
import styles from "./TaskView.module.scss";
import { toast } from "react-toastify";
import moment from "moment";

function TaskView() {
  const { countriesStates } = useContext(CountriesStatesContext);
  const { currentUser } = useContext(UserDataContext);

  const [taskContent, setTaskContent] = useState<TaskDetailsI | null>(null);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    params.taskId &&
    getTaskViewData(
      params.taskId,
      currentUser!.timezone.value,
      !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
      !isUserHavePermissions(currentUser!, [
        PermissionsList.viewMyServiceNote,
      ]) &&
      isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
        ? currentUser!.uid
        : undefined
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length
          ? data.response?.data?.errors[0]?.detail
          : "");
      } else {
        setTaskContent(data);
      }
    });
  };

  const CompleteTask = () => {
    completeTask(params.taskId!, currentUser!.timezone.value).then(
      (data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : "");
        } else {
          toast.success("succefully completed!");
          setTaskContent({
            ...taskContent!,
            status: {
              name: TaskStatuses.completed,
              date: data.data.data.attributes.completion_date,
            },
          });
        }
      }
    );
  };

  return (
    <>
      <Header
        title={"Task"}
        toggleBack={() => navigate(-1)}
        toggleReload={() => getContent()}
        opacity={0}
      />
      <LoadingSpinner isLoading={!taskContent} height={"300px"}>
        {taskContent ? (
          <div className={styles["up-container"]}>
            <div className={styles["up-card"]}>
              <div className={styles["up-card-title"]}>
                {taskContent.title}
                {checkPermissionsForEdit(
                  currentUser!,
                  PermissionsList.editAnyTask,
                  PermissionsList.editOwnTask,
                  taskContent.authorId
                ) && (
                  <Button
                    classes={{ root: styles["up-card-title-edit"] }}
                    color={"inherit"}
                    onClick={() => navigate("edit")}
                  >
                    Edit
                  </Button>
                )}
              </div>

              <div className={styles["up-card-title-location"]}>
                {taskContent.location.title}
              </div>

              <div className={styles["up-card-footer"]}>
                <div className={styles.address}>
                  {formatAddressToString(
                    taskContent.location.address,
                    countriesStates
                  )}
                </div>
                {!(taskContent.status?.name === TaskStatuses.completed) ? (
                  <Button
                    classes={{ root: styles["up-card-footer-button"] }}
                    color={"inherit"}
                    onClick={CompleteTask}
                  >
                    Complete
                  </Button>
                ) : (
                  <div className={styles["status"]}>
                    <img src={TickIcon} alt="tick" />
                    Completed <br />
                    {taskContent.status.date &&
                      checkDate12AMto00(
                        taskContent.status.date,
                        currentUser!.timezone.value
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className={styles["up-content"]}>
              <div className={styles["up-description"]}>
                <label className={styles["up-content-label"]}>Description</label>
                <div className={styles["up-content-value"]}>
                  {taskContent.description}
                </div>
              </div>
              <div className={styles["up-deadline"]}>
                <label className={styles["up-content-label"]}>Deadline (end of day)</label>
                <div className={styles["up-content-value"]}>
                  {moment(taskContent.deadline).format("ll")}
                </div>
              </div>
              <div className={styles["up-priority"]}>
                <label className={styles["up-content-label"]}>Priority (High priority tasks affect how the system
                  generated agent routes)</label>
                <div className={`${styles["up-content-value"]} ${taskContent.priority === 'high' ? styles.high : ''}`}>
                  {TaskPriority[taskContent.priority as 'regular' | 'high']}
                </div>
              </div>
              {taskContent.products.length ? (
                <>
                  <label className={styles["up-content-label"]}>Products</label>
                  <div className={styles["up-content-value"]}>
                    {taskContent.products.map((i) => i.title).join(", ")}
                  </div>
                </>
              ) : null}
            </div>

            <div className={styles["up-notes"]}>
              <NotesCards
                content={taskContent.notes}
                ids={{ taskId: params.taskId! }}
              ></NotesCards>
            </div>
          </div>
        ) : (
          <></>
        )}
      </LoadingSpinner>
    </>
  );
}

export default TaskView;
