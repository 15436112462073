import axios, { AxiosError } from "axios";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { TrafficModelE, UnitSystemE } from "shared/Enums";
import { saveRoutingSettings } from "shared/Services";
import * as Yup from "yup";

import { RoutingSettingsI } from "@interfaces";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import styles from "./RoutingSettings.module.scss";
import { toast } from "react-toastify";

function RoutingSettings({}: {}) {
  const { currentUser } = useContext(UserDataContext);

  const validationSchema = Yup.object().shape({
    avoidFerries: Yup.boolean().required("This field is required"),
    avoidHighways: Yup.boolean().required("This field is required"),
    avoidTolls: Yup.boolean().required("This field is required"),
    trafficModel: Yup.string().required("This field is required"),
    unitSystem: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      avoidFerries: currentUser!.routerSettings.avoidFerries,
      avoidHighways: currentUser!.routerSettings.avoidHighways,
      avoidTolls: currentUser!.routerSettings.avoidTolls,
      trafficModel: currentUser!.routerSettings.trafficModel,
      unitSystem: currentUser!.routerSettings.unitSystem,
    } as RoutingSettingsI,
    validationSchema,
    onSubmit: (values) => {
      saveRoutingSettings(currentUser!.routerSettings.id!, values).then(
        (data: AxiosError | any) => {
          if (axios.isAxiosError(data)) {
            toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
          } else {
            toast.success("successfully completed!");
          }
        }
      );
    },
  });

  return (
    <>
      <div className={styles["up-container"]}>
        <form onSubmit={formik.handleSubmit} className={styles["up-form"]}>
          <div className={styles["up-form-field"]}>
            <FormControlLabel
              control={
                <Checkbox
                  id="avoidFerries"
                  name="avoidFerries"
                  value={formik.values.avoidFerries}
                  onChange={formik.handleChange}
                />
              }
              label="avoid ferries"
            />
            <span className={styles["up-form-description"]}>
            Instructs the Directions service to avoid ferries where possible.
          </span>
          </div>
          <div className={styles["up-form-field"]}>
            <FormControlLabel
              control={
                <Checkbox
                  id="avoidTolls"
                  name="avoidTolls"
                  value={formik.values.avoidTolls}
                  onChange={formik.handleChange}
                />
              }
              label="avoid highways"
            />
            <span className={styles["up-form-description"]}>
            Instructs the Directions service to avoid highways where possible.
          </span>
          </div>
          <div className={styles["up-form-field"]}>
            <FormControlLabel
              control={
                <Checkbox
                  id="avoidHighways"
                  name="avoidHighways"
                  value={formik.values.avoidHighways}
                  onChange={formik.handleChange}
                />
              }
              label="avoid tolls"
            />
            <span className={styles["up-form-description"]}>
            Instructs the Directions service to avoid toll roads where possible.
          </span>
          </div>

          <div className={styles["up-form-field"]}>
            <label className={styles["up-form-label"]}>Map unit system</label>
            <RadioGroup
              row
              id="unitSystem"
              name="unitSystem"
              value={formik.values.unitSystem}
              onChange={formik.handleChange}
              classes={{ root: styles["up-form-radio-group"] }}
            >
              <FormControlLabel
                classes={{ root: styles["up-form-radio-label"] }}
                value={UnitSystemE.metric}
                control={<Radio />}
                label="METRIC"
              />
              <FormControlLabel
                classes={{ root: styles["up-form-radio-label"] }}
                value={UnitSystemE.imperial}
                control={<Radio />}
                label="IMPERIAL"
              />
            </RadioGroup>
          </div>
          <div className={styles["up-form-field"]}>
            <label className={styles["up-form-label"]}>Map traffic model</label>
            <RadioGroup
              row
              id="trafficModel"
              name="trafficModel"
              value={formik.values.trafficModel}
              onChange={formik.handleChange}
              classes={{ root: styles["up-form-radio-group"] }}
            >
              <FormControlLabel
                classes={{ root: styles["up-form-radio-label"] }}
                value={TrafficModelE.bestguess}
                control={<Radio />}
                label="Best guess"
              />
              <FormControlLabel
                classes={{ root: styles["up-form-radio-label"] }}
                value={TrafficModelE.pessimistic}
                control={<Radio />}
                label="Pessimistic"
              />

              <FormControlLabel
                classes={{ root: styles["up-form-radio-label"] }}
                value={TrafficModelE.optimistic}
                control={<Radio />}
                label="Optimistic"
              />
            </RadioGroup>
          </div>

          <Button
            type="submit"
            color={"inherit"}
            classes={{ root: styles["up-form-button"] }}
            variant="outlined"
          >
            SAVE
          </Button>
        </form>
      </div>
    </>
  );
}

export default RoutingSettings;
