import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import PageNotFound from "../../PageNotFound/PageNotFound";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import LoginPage from "./Login/Login";
import ResetPassword from "./ResetPassword/ResetPassword";

function AuthRouter() {
  return (
    <Routes>
      <Route path={"*"} element={<PageNotFound />} />
      <Route path={"reset-password"} element={<ResetPassword />} />
      <Route path={"forgot-password"} element={<ForgotPassword />} />
      <Route path={``} element={<LoginPage />} />
    </Routes>
  );
}

export default AuthRouter;
