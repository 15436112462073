import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import TasksForm from "components/Shared/Tasks/TasksForm/TasksForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createTask } from "shared/Services";

import { TaskCreateFormI } from "@interfaces";

import styles from "./TaskCreate.module.scss";
import { toast } from "react-toastify";

function TaskCreate({ locationId }: { locationId?: string }) {
  const navigate = useNavigate();
  const params = useParams();

  const [submitted, setSubmitted] = useState(0);

  const FormSubmit = (value: TaskCreateFormI) => {
    createTask(value).then((response: AxiosError | any) => {
      if (axios.isAxiosError(response)) {
        toast.error(response.message);
      } else {
        toast.success("successfully completed!");
        if (response.data.validation) {
          toast.warning(response.data.validation.message);
        }

        navigate(`/tasks/${response.data.data.id}`);
      }
    });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Create task"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        onCancelToggle={() => navigate(-1)}
      />

      <TasksForm
        locationId={params.locationId}
        submitted={submitted}
        formFinished={FormSubmit}
      ></TasksForm>
    </div>
  );
}

export default TaskCreate;
