import axios, { AxiosError } from "axios";
import ViewResources from "components/Pages/Visits/Resources/Resources";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import BlockLocationSidenav from "components/Shared/Locations/BlockLocations/BlockLocations";
import NotesCards from "components/Shared/Notes/NotesCards/NotesCards";
import UpAvatar from "components/Shared/Profile/Avatar/Avatar";
import TasksCards from "components/Shared/Tasks/TasksCards/TasksCards";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CountriesStatesContext, RouterContext, UserDataContext, } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { checkPermissionsForEdit, formatAddressToString, isUserHavePermissions, } from "shared/helpers/common.helper";
import { blockLocation, getLocationViewData } from "shared/Services";
import { getLocationResources } from "shared/Services/Locations.service";

import { LocationBlockFormI, LocationViewI, ResourceI, ResourcesListI, } from "@interfaces";
import { Button } from "@mui/material";

import LocationIcon from "../../../../assets/icons/location.svg";
import styles from "./LocationView.module.scss";
import { toast } from "react-toastify";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { WorkingHoursFormat } from "shared/helpers/profile.helper";

const moment = require("moment-timezone");

function LocationView() {
  const { countriesStates } = useContext(CountriesStatesContext);
  const [routerValue, setRouterValue] = useContext(RouterContext);
  const { currentUser } = useContext(UserDataContext);

  const [openedBlock, setOpenedBlock] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [locationInfo, setLocationInfo] = useState<LocationViewI | null>(null);
  const [resourcesInfo, setResourcesInfo] = useState<ResourcesListI | null>(null);
  const [resourcesList, setResourcesList] = useState<ResourceI[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const pageSize = 10;

  const getContent = () => {
    params.locationId &&
    getLocationViewData(params.locationId,
      currentUser?.timezone.offset,
      {
        userIdForTask:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
          !isUserHavePermissions(currentUser!, [
            PermissionsList.viewMyServiceTask,
          ]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
            ? currentUser!.uid
            : undefined,
        userIdForNotes:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
          !isUserHavePermissions(currentUser!, [
            PermissionsList.viewMyServiceNote,
          ]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
            ? currentUser!.uid
            : undefined,
      }).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length
          ? data.response?.data?.errors[0]?.detail
          : "");
      } else {
        setLocationInfo(data);
        setPageNumber(0);
        getLocationResources(params.locationId!, pageSize, 0).then(
          (resourcesData) => {
            if (axios.isAxiosError(resourcesData)) {
              toast.error(resourcesData.message);
            } else {
              setResourcesInfo(resourcesData);
              setResourcesList(resourcesData.items);
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  useEffect(() => {
    if (pageNumber > 0) {
      getLocationResources(
        params.locationId!,
        pageSize,
        pageNumber * pageSize
      ).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.message);
        } else {
          setHasMore(resourcesList.length + data.items.length < data.count);
          setResourcesList([...resourcesList, ...data.items]);
        }
      });
    }
  }, [pageNumber]);

  const openMapApp = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes("android");
    const isiOS = /(iphone|ipad|ipod|ios)/i.test(userAgent);

    if (isAndroid) {
      window.location.href = `geo:0,0?q=${locationInfo!.geolocation.lat},${
        locationInfo!.geolocation.lng
      }`;
    } else if (isiOS) {
      window.location.href = `maps://?q=${locationInfo!.geolocation.lat},${
        locationInfo!.geolocation.lng
      }`;
    } else {
      toast.info("Unsupported device");
    }
  };

  const saveBlock = (value?: LocationBlockFormI) => {
    if (value) {
      blockLocation(params.locationId!, value).then(
        (data: AxiosError | any) => {
          if (axios.isAxiosError(data)) {
            toast.error(data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : "");
          } else {
            toast.success("successfully completed!");
            locationInfo &&
            setLocationInfo({
              ...locationInfo,
              blocked: value.isBlocked,
              blockedUntil:
                value.isBlocked && value.isDateSelected
                  ? value.endDate
                  : undefined,
            });
          }
        }
      );
    }
    setOpenedBlock(false);
  };

  return (
    <>
      <Header
        title={"Location"}
        toggleBack={() => navigate(-1)}
        opacity={0}
        toggleReload={() => getContent()}
      />
      <LoadingSpinner isLoading={!locationInfo} height={"300px"}>
        {locationInfo ? (
          <div className={styles["up-container"]}>
            <div
              className={`${styles["up-card"]} ${
                locationInfo.blocked ? styles["blocked"] : ""
              }`}
            >
              {checkPermissionsForEdit(
                currentUser!,
                PermissionsList.editAnyLocation,
                PermissionsList.editOwnLocation,
                locationInfo.authorId
              ) && (
                <div className={styles["up-card-edit"]}>
                  <Button
                    classes={{ root: styles["up-card-edit-button"] }}
                    color={"inherit"}
                    onClick={() => navigate("edit")}
                  >
                    Edit
                  </Button>
                </div>
              )}
              <div className={styles["up-card-priority"]}>
                {locationInfo.priority?.title}
              </div>
              <div className={styles["up-card-avatar"]}>
                {locationInfo.avatar ? (
                  <UpAvatar
                    name={locationInfo.name}
                    avatar={locationInfo.avatar?.url || null}
                    defaultImage={LocationIcon}
                  />
                ) : (
                  <img
                    className={styles["up-card-avatar-none"]}
                    alt={locationInfo.name}
                    src={LocationIcon}
                  />
                )}
              </div>
              <div className={styles["up-card-name"]}>{locationInfo.name}</div>
              {locationInfo.blocked && locationInfo.blockedUntil && (
                <div className={styles["up-card-blocked"]}>
                  Temporarily blocked until{" "}
                  {moment
                    .utc(locationInfo.blockedUntil)
                    .tz(currentUser!.timezone.value)
                    .format("MMM D, YYYY")}
                </div>
              )}
            </div>
            <div className={styles["up-buttons"]}>
              {checkPermissionsForEdit(
                currentUser!,
                PermissionsList.editAnyLocation,
                PermissionsList.editOwnLocation,
                locationInfo.authorId
              ) && (
                <>
                  {locationInfo.blocked ? (
                    <Button
                      classes={{
                        root: `${styles["up-buttons-button"]} ${styles["unskip"]}`,
                      }}
                      onClick={() => setOpenedBlock(true)}
                      color={"inherit"}
                      variant="outlined"
                    >
                      Unblock visits
                    </Button>
                  ) : (
                    <Button
                      classes={{ root: styles["up-buttons-button"] }}
                      onClick={() => setOpenedBlock(true)}
                      color={"inherit"}
                      variant="outlined"
                    >
                      Block visits
                    </Button>
                  )}
                </>
              )}
              {isUserHavePermissions(
                currentUser as UserDataContextI,
                [PermissionsList.createAppointment]
              ) && <Button
                classes={{ root: styles["up-buttons-button"] }}
                color={"inherit"}
                onClick={() => navigate(`/visits/create?locId=${locationInfo.id}&locTitle=${locationInfo.name}`)}
                variant="outlined"
              >
                New appointment
              </Button>}

              <Button
                classes={{ root: styles["up-buttons-button"] }}
                color={"inherit"}
                onClick={() => openMapApp()}
                variant="outlined"
              >
                Direction
              </Button>

              <Button
                classes={{ root: styles["up-buttons-button"] }}
                color={"inherit"}
                variant="outlined"
                onClick={() =>
                  setRouterValue({
                    ...routerValue,
                    gMapOpened: true,
                    gMapGeolocation: locationInfo.geolocation,
                    gMapLocationName: locationInfo?.name,
                  })
                }
              >
                Map
              </Button>
            </div>
            <div className={styles["up-content"]}>
              {locationInfo.lastVisit ? (
                <>
                  <label className={styles["up-content-label"]}>
                    Last Visit
                  </label>
                  <div className={styles["up-content-value"]}>
                    {moment
                      .utc(locationInfo.lastVisit)
                      .tz(currentUser!.timezone.value)
                      .format("MMM D, YYYY")}
                  </div>
                </>
              ) : null}

              <label className={styles["up-content-label"]}>Address</label>

              {locationInfo.address && <div className={styles["up-content-value"]}>
                {formatAddressToString(locationInfo.address, countriesStates)}
              </div>}

              {locationInfo.phoneNumbers?.length ? (
                <>
                  <label className={styles["up-content-label"]}>
                    Telephones
                  </label>
                  <div className={styles["up-content-value"]}>
                    {locationInfo.phoneNumbers.map((p, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={`tel:${p}`}
                      >
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}

              {locationInfo.emails?.length ? (
                <>
                  <label className={styles["up-content-label"]}>Email</label>
                  <div className={styles["up-content-value"]}>
                    {locationInfo.emails.map((p, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={`mailto:${p}`}
                      >
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}
              {isUserHavePermissions(currentUser!, [
                PermissionsList.viewAnyContact,
                PermissionsList.viewOwnContact,
              ]) && locationInfo.contacts.length ? (
                <>
                  <label className={styles["up-content-label"]}>Contacts</label>
                  <div className={styles["up-content-value"]}>
                    {locationInfo.contacts.map((i) => (
                      <div key={i.id} className={styles["up-w-full"]}>
                        <span onClick={() => navigate(`/contacts/${i.id}`)}>
                          {i.name}
                        </span>
                        {i.phoneNumbers?.length ? (
                          <>
                            {i.phoneNumbers.map((p, index) => (
                              <a
                                key={index}
                                rel="noreferrer"
                                target="_blank"
                                href={`tel:${p}`}
                              >
                                {p}
                              </a>
                            ))}
                          </>
                        ) : null}

                        {i.emails?.length ? (
                          <>
                            {i.emails.map((p, index) => (
                              <a
                                key={index}
                                rel="noreferrer"
                                target="_blank"
                                href={`mailto:${p}`}
                              >
                                {p}
                              </a>
                            ))}
                          </>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}

              {isUserHavePermissions(currentUser!, [
                PermissionsList.viewAnyCompany,
                PermissionsList.viewOwnCompany,
              ]) && (
                <>
                  <label className={styles["up-content-label"]}>Company</label>
                  <div className={styles["up-content-value"]}>
                    <span
                      onClick={() =>
                        navigate(`/companies/${locationInfo.company.id}`)
                      }
                    >
                      {locationInfo.company.name}
                    </span>
                  </div>
                </>
              )}

              {locationInfo.hasTimeSchedule && <>
                <label className={styles["up-content-label"]}>
                  Working Hours in <strong>{currentUser?.timezone.value || "UTC"}</strong> timezone
                </label>
                <div
                  className={styles["up-content-value"]}
                  style={{ flexDirection: "column" }}
                >
                  {locationInfo.workingHours
                    ?.filter((i) => i.checked)
                    ?.map((i, index) => {
                        return (
                          <div key={index}>{WorkingHoursFormat(i)}</div>
                        );
                      }
                    )}
                </div>
              </>}
            </div>

            {isUserHavePermissions(currentUser!, [
              PermissionsList.viewAnyNote,
              PermissionsList.viewOwnNote,
              PermissionsList.viewMyServiceNote,
            ]) && (
              <div className={styles["up-notes"]}>
                <NotesCards
                  ids={{ locationId: locationInfo.id }}
                  content={locationInfo.notes}
                ></NotesCards>
              </div>
            )}
            {isUserHavePermissions(currentUser!, [
              PermissionsList.viewAnyTask,
              PermissionsList.viewOwnTask,
              PermissionsList.viewMyServiceTask,
            ]) && (
              <div className={styles["up-tasks"]}>
                <TasksCards location={locationInfo}></TasksCards>
              </div>
            )}

            {resourcesInfo ? (
              <div className={styles["up-resources"]}>
                <ViewResources
                  content={resourcesInfo}
                  canAdd={false}
                  scrollData={{
                    hasMore,
                    setPageNumber,
                    pageNumber,
                    items: resourcesList,
                  }}
                ></ViewResources>
              </div>
            ) : null}
            <BlockLocationSidenav
              opened={openedBlock}
              onToggle={(e) => saveBlock(e)}
              locationName={locationInfo.name}
              blocked={locationInfo.blocked}
              blockedUntil={locationInfo.blockedUntil}
            />
          </div>
        ) : (
          <></>
        )}
      </LoadingSpinner>
    </>
  );
}

export default LocationView;
