import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import ContactCreate from "./ContactCreate/ContactCreate";
import ContactEdit from "./ContactsEdit/ContactEdit";
import ContactView from "./ContactView/ContactView";

function ContactsRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      {isUserHavePermissions(currentUser!, [
        PermissionsList.viewAnyContact,
        PermissionsList.viewOwnContact,
      ]) && <Route path="/:contactId" element={<ContactView />} />}

      {isUserHavePermissions(currentUser!, [PermissionsList.createContact]) && (
        <Route path="/create" element={<ContactCreate />} />
      )}

      {isUserHavePermissions(currentUser!, [
        PermissionsList.editAnyContact,
        PermissionsList.editOwnContact,
      ]) && <Route path="/:contactId/edit" element={<ContactEdit />} />}

      <Route path={`*`} element={<PageNotFound />} />
    </Routes>
  );
}

export default ContactsRouter;
