import axios from "axios";
import AddressField from "components/Shared/Forms/AddressField/AddressField";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import GeolocationField from "components/Shared/Forms/GeolocationField/GeolocationField";
import UpTimePicker from "components/Shared/ScrollablePicker/ScrollablePicker";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CountriesStatesContext,
  UserDataContext,
} from "shared/Contexts";
import { formatAddressToString } from "shared/helpers/common.helper";
import {
  formatNumberToString,
  formatStringToTimeFormat,
  TimeFormatToString,
} from "shared/helpers/time.helper";
import { TimeOptionGroups } from "shared/MockedData/TimePicker.mocked";
import {
  attachGeolocationToAgentProfile,
  createStartLocation,
  getLocationsAdresses,
} from "shared/Services";
import { getLocationTime } from "shared/Services/Router.service";
import { addressValidation } from "shared/Validation/Validation";
import * as Yup from "yup";

import { AddressDto, LatLngI, StartLocationI, TimeFormatI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

import styles from "./RouteStart.module.scss";
import { toast } from "react-toastify";

const moment = require("moment-timezone");

function RouteStart() {
  const { currentUser } = useContext(UserDataContext);
  const { countriesStates } = useContext(CountriesStatesContext);

  const navigate = useNavigate();

  const [timeValue, setTimeValue] = useState<TimeFormatI | null>(null);
  const [newAddress, setNewAddress] = useState<boolean>(false);
  const [content, setContent] = useState<StartLocationI[]>([]);
  const [selectedAddressId, setSelectedAddressId] = useState<null | string>(
    null
  );
  const [type, setType] = useState<"start" | "end">("start");
  const params = useParams();

  useEffect(() => {
    if (params.type === "end" || params.type === "start") {
      setType(params.type);
    } else {
      navigate("/");
      return;
    }
    getLocationsAdresses(currentUser!.uid).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setContent(data);
      }
    });

    getLocationTime(params.type).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setTimeValue(formatStringToTimeFormat(formatNumberToString(data)));
      }
    });
  }, []);

  const formSubmit = () => {
    if (newAddress) {
      formik.handleSubmit();
    } else if (selectedAddressId) {
      attachGeolocationToAgentProfile(
        currentUser!.uid,
        selectedAddressId,
        type,
        TimeFormatToString(timeValue!)
      ).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
        } else {
          toast.success("succefully completed!");
          navigate("/");
        }
      });
    }
  };

  const validationSchema = Yup.object().shape({
    address: addressValidation,
    geolocation: Yup.object().shape({
      lat: Yup.string().required("This field is required"),
      lng: Yup.string().required("This field is required"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      address: {
        address_line: "",
        city: "",
        country_code: "",
        postal_code: "",
        state: "",
      },
      geolocation: {
        lat: 0,
        lng: 0,
      },
    } as {
      geolocation: LatLngI;
      address: AddressDto;
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createStartLocation(
        currentUser!.uid,
        values,
        type,
        TimeFormatToString(timeValue!)
      ).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
        } else {
          toast.success("succefully completed!");
          navigate("/");
        }
      });
    },
  });

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title={
          type === "start"
            ? "Set your Start location and time"
            : "Set your End location and time"
        }
        position="fixed"
        onRightBtnToggle={() => formSubmit()}
        onCancelToggle={() => navigate("/")}
      />
      <div className={styles["up-recent"]}>
        <h1 className={styles["up-recent-title"]}>Recent locations</h1>

        <div className={styles["up-recent-grid"]}>
          {content.map((i) => (
            <div
              className={`${styles["up-recent-grid-item"]} ${
                selectedAddressId === i.id ? styles["active"] : ""
              }`}
              key={i.id}
              onClick={() => {
                setSelectedAddressId(i.id);
                newAddress && setNewAddress(false);
              }}
            >
              {formatAddressToString(i.address, countriesStates)}
            </div>
          ))}
        </div>
      </div>

      <div className={styles["up-address"]}>
        {!newAddress && (
          <Button
            classes={{ root: styles["up-address-button"] }}
            variant="outlined"
            startIcon={<AddIcon color="inherit" />}
            onClick={() => {
              setNewAddress(true);
              setSelectedAddressId(null);
            }}
          >
            Create New Address
          </Button>
        )}
        <div style={newAddress ? { display: "block" } : { display: "none" }}>
          <h1 className={styles["up-address-title"]}>New address</h1>

          <div className={styles["up-form-address"]}>
            <AddressField
              toggleValue={(e) => formik.setFieldValue("address", e)}
              errors={formik.errors.address}
              touched={formik.touched.address}
            ></AddressField>
          </div>
          <div className={styles["up-form-geolocation"]}>
            <GeolocationField
              searchValue={formik.values.address}
              toggleValue={(e) => formik.setFieldValue("geolocation", e)}
              errors={formik.errors.geolocation}
              touched={formik.touched.geolocation}
            ></GeolocationField>
          </div>
        </div>
      </div>
      <div className={styles["up-time"]}>
        <h1 className={styles["up-time-title"]}>Set time</h1>

        <div className={styles["up-time-block"]}>
          {timeValue && (
            <UpTimePicker
              optionGroups={TimeOptionGroups}
              valueGroups={
                timeValue as unknown as {
                  [key: string]: string;
                }
              }
              onChange={(name: string, value: string) =>
                setTimeValue({
                  ...timeValue,
                  [name]: value,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RouteStart;
