import styles from "./PasswordPolicies.module.scss";
import { useEffect, useState } from "react";
import { getPasswordPolicies } from "../../../shared/Services/Auth.service";
import axios from "axios";
import { toast } from "react-toastify";

const PasswordPolicies = () => {
  const [policies, setPolicies] = useState<[string]>();

  useEffect(() => {
    getPasswordPolicies().then(resp => {
      if (axios.isAxiosError(resp)) {
        toast.error("Unable to get password policies.");
      } else {
        setPolicies(resp.data?.default);
      }
    });
  }, []);

  return policies
    ? <ul className={`${styles["up-policies"]}`}>
      {policies.map(p => <li key={p}>{p}</li>)}
    </ul>
    : null;
};

export default PasswordPolicies;
