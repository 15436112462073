import moment from "moment";
import { useState } from "react";

import { LocationBlockFormI } from "@interfaces";
import {
  Checkbox,
  FormControlLabel,
  SwipeableDrawer,
  Switch,
  TextField,
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers-pro";

import CalendarIcon from "../../../../assets/icons/navbar/calendar.svg";
import FormsHeader from "../../Forms/FormsHeader/FormsHeader";
import styles from "./BlockLocations.module.scss";

interface SidenavIProps {
  opened: boolean;
  onToggle: (result?: LocationBlockFormI) => void;
  locationName: string;
  blocked: boolean;
  blockedUntil?: string;
}

function BlockLocationSidenav({
  opened,
  locationName,
  onToggle,
  blocked,
  blockedUntil,
}: SidenavIProps) {
  const [endDate, setEndDate] = useState(blockedUntil);
  const [isDateSelected, setIsDateSelected] = useState(!!blockedUntil);
  const [isBlocked, setIsBlocked] = useState(blocked);
  return (
    <>
      <SwipeableDrawer
        open={opened}
        onClose={() => onToggle()}
        onOpen={() => onToggle()}
        anchor="bottom"
        swipeAreaWidth={44}
        disableSwipeToOpen={true}
        classes={{ paper: styles["up-drawer"] }}
      >
        <FormsHeader
          title="Block Location"
          position="relative"
          onCancelToggle={() => onToggle()}
          onRightBtnToggle={() =>
            onToggle({ endDate, isDateSelected, isBlocked })
          }
        />

        <div className={styles["up-container"]}>
          <h2 className={styles["up-container-title"]}>{locationName}</h2>

          <FormControlLabel
            classes={{
              root: styles["up-container-checkbox"],
              label: styles["up-container-checkbox-label"],
            }}
            control={
              <Checkbox
                color="primary"
                checked={isBlocked}
                onChange={(e) => setIsBlocked(e.target.checked)}
              />
            }
            label="Block visits until firther notice"
          />

          <div
            className={styles["up-datepicker"]}
            style={!isBlocked ? { display: "none" } : { display: "block" }}
          >
            <div className={styles["up-datepicker-header"]}>
              <img
                className={styles["up-datepicker-header-icon"]}
                src={CalendarIcon}
                alt="Date"
              />
              <span className={styles["up-datepicker-header-title"]}>Date</span>

              <Switch
                color="default"
                checked={isDateSelected}
                onChange={(e) => setIsDateSelected(e.target.checked)}
                classes={{
                  root: styles["up-switch"],
                  thumb: styles["up-switch-thumb"],
                  track: styles["up-switch-track"],
                  switchBase: styles["up-switch-switch-base"],
                  checked: styles["up-switch-checked"],
                }}
              />
            </div>
            {isDateSelected && (
              <div className={styles["up-datepicker-item"]}>
                <StaticDatePicker
                  className={"mat-calendar"}
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={endDate}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    newValue &&
                      setEndDate(moment(newValue).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            )}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
}

export default BlockLocationSidenav;
