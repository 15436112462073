import axios from "axios";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserDataContext } from "shared/Contexts";
import { getUsersList } from "shared/Services";
import * as Yup from "yup";

import { AgentsListI } from "@interfaces";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import styles from "./AgentsListField.module.scss";
import { toast } from "react-toastify";

function AgentsListField({
  selectedAgentsList,
  toggleValue,
  errors,
  touched,
}: {
  selectedAgentsList?: AgentsListI[];
  toggleValue: (e: string[]) => void;
  errors?: FormikErrors<string[] | string>;
  touched?: FormikTouched<Boolean>;
}) {
  const [AgentsListList, setAgentsListLists] = useState<AgentsListI[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const { currentUser } = useContext(UserDataContext);

  const handleChange = (e: string) => {
    const array = formik.values.AgentsList;
    let index = array.indexOf(e);
    index !== -1 ? array.splice(index, 1) : array.push(e);

    formik.setFieldValue("AgentsList", array);
    toggleValue(array);
  };

  useEffect(() => {
    getUsersList(pageNumber * 10, currentUser!.uid).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setHasMore(data.hasMore);
        setAgentsListLists([
          ...AgentsListList,
          ...data.content?.filter(
            (item1: AgentsListI) =>
              !selectedAgentsList?.some((item2) => item1.id === item2.id)
          ),
        ]);
      }
    });
  }, [pageNumber]);

  const validationSchema = Yup.object().shape({
    AgentsList: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      AgentsList: selectedAgentsList?.map((i: { id: any }) => i.id) || [],
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  return (
    <>
      <FormControl className={styles["up-form"]}>
        <InputLabel>Agents</InputLabel>
        <Select
          classes={{ select: styles["up-form-input"] }}
          variant="outlined"
          fullWidth={true}
          id="AgentsList"
          name="AgentsList"
          value={formik.values.AgentsList}
          multiple
          displayEmpty
          error={touched && Boolean(errors)}
          label={"AgentsList"}
        >
          {selectedAgentsList?.length
            ? selectedAgentsList.map((i) => (
                <MenuItem style={{ display: "none" }} value={i.id} key={i.id}>
                  {i.title}
                </MenuItem>
              ))
            : null}
          {AgentsListList.map((i) => (
            <MenuItem style={{ display: "none" }} value={i.id} key={i.id}>
              {i.title}
            </MenuItem>
          ))}
          <div className={styles["up-menus"]}>
            <InfiniteScroll
              dataLength={
                AgentsListList.length + (selectedAgentsList?.length || 0)
              }
              next={() => setPageNumber(pageNumber + 1)}
              hasMore={hasMore}
              height={200}
              loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
            >
              {selectedAgentsList?.map((i) => (
                <Button
                  value={i.id}
                  key={i.id}
                  className={`${styles["up-menu-item"]} ${
                    formik.values.AgentsList.includes(i.id)
                      ? styles["active"]
                      : ""
                  }`}
                  onClick={(e) => {
                    handleChange(i.id);
                  }}
                >
                  {i.title}
                </Button>
              ))}
              {AgentsListList.map((i) => (
                <Button
                  value={i.id}
                  key={i.id}
                  className={`${styles["up-menu-item"]} ${
                    formik.values.AgentsList.includes(i.id)
                      ? styles["active"]
                      : ""
                  }`}
                  onClick={(e) => {
                    handleChange(i.id);
                  }}
                >
                  {i.title}
                </Button>
              ))}
            </InfiniteScroll>
          </div>
        </Select>
      </FormControl>
    </>
  );
}

export default AgentsListField;
