import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import AllNotesView from "./AllNotesView/AllNotesView";
import NoteCreate from "./NoteCreate/NoteCreate";
import NoteEdit from "./NoteEdit/NoteEdit";
import NotesView from "./NotesView/NotesView";
import NoteView from "./NoteView/NoteView";

function NotesRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      {isUserHavePermissions(currentUser!, [
        PermissionsList.viewAnyLocation,
        PermissionsList.viewOwnLocation,
        PermissionsList.viewMyServiceLocation,
      ]) &&
        isUserHavePermissions(currentUser!, [
          PermissionsList.viewAnyNote,
          PermissionsList.viewOwnNote,
          PermissionsList.viewMyServiceNote,
        ]) && (
          <>
            <Route path="/location/:locationId" element={<NotesView />} />
            <Route path="/" element={<AllNotesView />} />
          </>
        )}

      {isUserHavePermissions(currentUser!, [
        PermissionsList.viewAnyNote,
        PermissionsList.viewOwnNote,
        PermissionsList.viewMyServiceNote,
      ]) && <Route path="/:noteId" element={<NoteView />} />}

      {isUserHavePermissions(currentUser!, [
        PermissionsList.editAnyNote,
        PermissionsList.editOwnNote,
      ]) && <Route path="/:noteId/edit" element={<NoteEdit />} />}

      {isUserHavePermissions(currentUser!, [PermissionsList.createNote]) && (
        <>
          <Route path="/create" element={<NoteCreate />} />
          <Route
            path="/create/locations/:locationId"
            element={<NoteCreate />}
          />
          <Route path="/create/tasks/:taskId" element={<NoteCreate />} />
          <Route path="/create/visits/:visitId" element={<NoteCreate />} />
        </>
      )}

      <Route path={`*`} element={<PageNotFound />} />
    </Routes>
  );
}

export default NotesRouter;
