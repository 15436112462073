import { AxiosResponse } from "axios";

import { helperApi, jsonApiFile, jsonAuthApi } from "./Settings.api.service";

export const apiGetLocationsList = (
  pageNumber: number,
  searchText: string,
  params?: {
    countriesStates?: { states: string[]; countries: string[] };
    companyId?: string;
    filterByAgent?: string;
    filterByUser?: string;
  }
): Promise<AxiosResponse<any, any>> => {
  const idFilter = params?.filterByUser
    ? {
        "filter[uid.id][value]": params?.filterByUser,
      }
    : {};
  const idServiceFilter = params?.filterByAgent
    ? {
        "filter[agent.id][value]": params?.filterByAgent,
      }
    : {};

  let statesParams: any = {};
  let countriesParams: any = {};
  let companyParams: any = {};

  if (params?.countriesStates?.states.length) {
    statesParams["filter[address.state][operator]"] = "IN";
    statesParams["filter[address.state][condition][memberOf]"] = "search-group";
    params?.countriesStates?.states.forEach((val, index) => {
      statesParams[`filter[address.state][value][${index}]`] = val;
    });
  }
  if (params?.countriesStates?.countries.length) {
    countriesParams["filter[address.country_code][operator]"] = "IN";
    countriesParams["filter[address.country_code][condition][memberOf]"] =
      "search-group";
    params?.countriesStates?.countries.forEach((val, index) => {
      countriesParams[`filter[address.country_code][value][${index}]`] = val;
    });
  }

  if (params?.companyId) {
    companyParams["filter[company.id]"] = params.companyId;
  } else {
    companyParams["filter[company.label][operator]"] = "CONTAINS";
    companyParams["filter[company.label][value]"] = searchText || "";
    companyParams["filter[company.label][condition][memberOf]"] =
      "search-group";
  }

  return jsonAuthApi.get(`/ups_location/ups_location`, {
    params: {
      sort: "label",
      "page[offset]": pageNumber,
      "page[limit]": "20",
      "filter[status][value]": "1",
      ...idFilter,
      ...idServiceFilter,
      include: "image,contacts,company,priority",
      "filter[search-group][group][conjunction]": "OR",
      "filter[label][operator]": "CONTAINS",
      "filter[label][value]": searchText || "",
      "filter[label][condition][memberOf]": "search-group",
      "filter[address.address_line][operator]": "CONTAINS",
      "filter[address.address_line][value]": searchText || "",
      "filter[address.address_line][condition][memberOf]": "search-group",
      "filter[address.city][operator]": "CONTAINS",
      "filter[address.city][value]": searchText || "",
      "filter[address.city][condition][memberOf]": "search-group",
      "filter[address.postal_code][operator]": "CONTAINS",
      "filter[address.postal_code][value]": searchText || "",
      "filter[address.postal_code][condition][memberOf]": "search-group",
      "filter[contacts.label][operator]": "CONTAINS",
      "filter[contacts.label][value]": searchText || "",
      "filter[contacts.label][condition][memberOf]": "search-group",
      ...statesParams,
      ...countriesParams,
      ...companyParams,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetLocationData = (
  id: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_location/ups_location/${id}`, {
    params: {
      include: "contacts,image,company,agent",
    },
  });
};
export const apiGetLocationName = (
  id: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_location/ups_location/${id}`);
};

export const apiGetLocationResources = (
  uuids: string[],
  pageSize: number,
  pageNumber: number
): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`image/location`, {
    params: {
      uuids,
      offset: pageNumber,
      limit: pageSize,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiEditLocationData = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_location/ups_location/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiCreateLocation = (
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/ups_location/ups_location/`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiDeleteLocation = (
  id: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.delete(`/ups_location/ups_location/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiUploadLocationLogo = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonApiFile.post(`/ups_location/ups_location/${id}/image`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetLocationsForNotes = (
  pageNumber: number,
  params?: {
    filterByAgent?: string;
    filterByUser?: string;
  }
): Promise<AxiosResponse<any, any>> => {
  const idFilter = params?.filterByUser
    ? {
        "filter[uid.id][value]": params?.filterByUser,
      }
    : {};

  const idServiceFilter = params?.filterByAgent
    ? {
        "filter[agent.id][value]": params?.filterByAgent,
      }
    : {};
  return jsonAuthApi.get(`/ups_location/ups_location`, {
    params: {
      sort: "label",
      "page[offset]": pageNumber,
      "page[limit]": "20",
      "filter[status][value]": "1",
      ...idFilter,
      ...idServiceFilter,
      "filter[isNull][condition][path]": "notes",
      "filter[isNull][condition][operator]": "IS NOT NULL",
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetTimezones = (): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`/timezones`);
};
