import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManagerContext, UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  CheckRoutingPermission,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { getUserName, Logout } from "shared/Services";

import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";

import CalendarIcon from "../../../assets/icons/navbar/calendar.svg";
import LocationsIcon from "../../../assets/icons/navbar/locations.svg";
import NotesIcon from "../../../assets/icons/navbar/notes.svg";
import ProfileIcon from "../../../assets/icons/navbar/profile.svg";
import ReportsIcon from "../../../assets/icons/navbar/reports.svg";
import SettingsIcon from "../../../assets/icons/navbar/settings.svg";
import ScheduleIcon from "../../../assets/icons/navbar/today-schedule.svg";
import styles from "./Sidenav.module.scss";
import { toast } from "react-toastify";

interface SidenavIProps {
  opened: boolean;
  onToggle: (condition: boolean) => void;
}

function Sidenav({ opened, onToggle }: SidenavIProps) {
  const { logout, currentUser } = useContext(UserDataContext);
  const [{ agent }] = useContext(ManagerContext);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();

  const HandleRedirect = (route: string) => {
    navigate(route);
    onToggle(false);
  };

  const navLogout = () => {
    Logout().then((resp) => {
      if (axios.isAxiosError(resp)) {
        toast.error(resp.message);
        return;
      }
      logout();
      navigate("/");
    });
  };

  useEffect(() => {
    getUserName(currentUser!.uid).then((resp) => {
      if (axios.isAxiosError(resp)) {
        toast.error(resp.response?.data?.errors[0]?.detail);
        return;
      }
      setUserName(resp);
    });
  }, []);

  return (
    <>
      <SwipeableDrawer
        open={opened}
        onClose={() => onToggle(false)}
        onOpen={() => onToggle(true)}
        anchor="bottom"
        swipeAreaWidth={44}
        disableSwipeToOpen={true}
        classes={{ paper: styles["up-drawer"] }}
      >
        <div className={styles["up-puller"]} />

        <List classes={{ root: styles["up-list"] }}>
          <div className={styles["up-user-name"]}>{userName}</div>
          <>
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`/`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img
                    alt="icon"
                    src={ScheduleIcon}
                    className={styles["up-list-img"]}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={agent ? `Day of ${agent.fullName}` : "Today’s Schedule"}
                  classes={{ root: `${styles["up-list-text"]} ${agent && styles["manager-mode"]}` }}
                />
              </Link>
            </ListItem>

            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`visits/calendar`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img
                    alt="icon"
                    src={CalendarIcon}
                    className={styles["up-list-img"]}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={agent ? `Calendar of ${agent.fullName}` : "Calendar"}
                  classes={{ root: `${styles["up-list-text"]} ${agent && styles["manager-mode"]}` }}
                />
              </Link>
            </ListItem>
          </>

          {isUserHavePermissions(currentUser!, [
            PermissionsList.viewAnyLocation,
            PermissionsList.viewOwnLocation,
            PermissionsList.viewMyServiceLocation,
          ]) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`locations`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img
                    alt="icon"
                    src={LocationsIcon}
                    className={styles["up-list-img"]}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={agent ? `Locations of ${agent.fullName}` : "Locations"}
                  classes={{ root: `${styles["up-list-text"]} ${agent && styles["manager-mode"]}` }}
                />
              </Link>
            </ListItem>
          )}

          {isUserHavePermissions(currentUser!, [
            PermissionsList.viewOwnNote,
            PermissionsList.viewAnyNote,
            PermissionsList.viewMyServiceNote,
          ]) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`notes`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img
                    alt="icon"
                    src={NotesIcon}
                    className={styles["up-list-img"]}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Notes (all)"}
                  classes={{ root: styles["up-list-text"] }}
                />
              </Link>
            </ListItem>
          )}

          <ListItem disablePadding>
            <Link
              underline="none"
              onClick={() => HandleRedirect(`reports`)}
              classes={{ root: styles["up-list-button"] }}
            >
              <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                <img
                  alt="icon"
                  src={ReportsIcon}
                  className={styles["up-list-img"]}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Reports"}
                classes={{ root: styles["up-list-text"] }}
              />
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link
              underline="none"
              onClick={() => HandleRedirect(`profile`)}
              classes={{ root: styles["up-list-button"] }}
            >
              <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                <img
                  alt="icon"
                  src={ProfileIcon}
                  className={styles["up-list-img"]}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Profile"}
                classes={{ root: styles["up-list-text"] }}
              />
            </Link>
          </ListItem>

          <ListItem disablePadding>
            <Link
              underline="none"
              onClick={() => HandleRedirect(`profile/settings`)}
              classes={{ root: styles["up-list-button"] }}
            >
              <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                <img
                  alt="icon"
                  src={SettingsIcon}
                  className={styles["up-list-img"]}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Settings"}
                classes={{ root: styles["up-list-text"] }}
              />
            </Link>
          </ListItem>

          <ListItem disablePadding>
            <Link
              underline="none"
              classes={{ root: styles["up-list-button"] }}
              onClick={() => navLogout()}
            >
              <ListItemText
                primary={"Logout"}
                classes={{ root: styles["up-list-text"] }}
              />
            </Link>
          </ListItem>
        </List>
        <div className={styles["up-version"]}>Version 0.25</div>
      </SwipeableDrawer>
    </>
  );
}

export default Sidenav;
