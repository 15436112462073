import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import TaskCreate from "./TaskCreate/TaskCreate";
import TaskEdit from "./TaskEdit/TaskEdit";
import TasksView from "./TasksView/TasksView";
import TaskView from "./TaskView/TaskView";

function TasksRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      {isUserHavePermissions(currentUser!, [
        PermissionsList.viewAnyTask,
        PermissionsList.viewOwnTask,
        PermissionsList.viewMyServiceTask,
      ]) && (
        <>
          <Route path="/" element={<TasksView />} />
          <Route path="/location/:locationId" element={<TasksView />} />
          <Route path="/:taskId" element={<TaskView />} />
        </>
      )}

      {isUserHavePermissions(currentUser!, [PermissionsList.createTask]) && (
        <>
          <Route path="create" element={<TaskCreate />} />
          <Route path="create/:locationId" element={<TaskCreate />} />
        </>
      )}

      {isUserHavePermissions(currentUser!, [
        PermissionsList.editAnyTask,
        PermissionsList.editOwnTask,
      ]) && <Route path="/:taskId/edit" element={<TaskEdit />} />}

      <Route path={`*`} element={<PageNotFound />} />
    </Routes>
  );
}

export default TasksRouter;
