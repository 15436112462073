import UpTimePicker from "components/Shared/ScrollablePicker/ScrollablePicker";
import { useContext, useEffect, useState } from "react";
import { DateFormI } from "shared/Enums";
import { WeekDays } from "shared/helpers/profile.helper";
import {
  changeAMPMString,
  formatStringToTimeFormat,
} from "shared/helpers/time.helper";
import { TimeOptionGroups } from "shared/MockedData/TimePicker.mocked";

import { WorkingHoursItemI } from "@interfaces";
import { Button, Checkbox } from "@mui/material";

import styles from "./WorkingHours.module.scss";

function WorkingHours({
  hours,
  hoursChanged,
}: {
  hours: WorkingHoursItemI[];
  hoursChanged: (value: WorkingHoursItemI[]) => void;
}) {
  const [workingHours, setWorkingHours] = useState(hours);
  const [selected, setSelected] = useState<{
    id: number;
    type: DateFormI;
  } | null>(null);

  useEffect(() => {
    hoursChanged(workingHours);
  }, [workingHours, hoursChanged]);

  return (
    <div className={styles["up-container"]}>
      {workingHours.map((workingDay, index) => {
        return (
          <div className={styles["up-content"]} key={index}>
            <div className={styles["up-row"]}>
              <Checkbox
                classes={{ root: styles["up-row-checkbox"] }}
                color={"primary"}
                checked={workingDay.checked}
                id={workingDay.day.toString()}
                name={workingDay.day.toString()}
                onChange={(e) => {
                  setSelected(null);
                  setWorkingHours(
                    workingHours.map((el) => {
                      if (el.day === workingDay.day) {
                        el.checked = e.target.checked;
                      }
                      return el;
                    })
                  );
                }}
              />
              <span className={styles["up-row-title"]}>
                {workingDay.name || WeekDays[workingDay.day]}
              </span>

              <Button
                disabled={!workingDay.checked}
                onClick={() => {
                  setSelected({
                    id: workingDay.day,
                    type: DateFormI.from,
                  });
                }}
                className={`${styles["up-row-tab"]} ${
                  selected &&
                  selected.id === workingDay.day &&
                  selected.type === DateFormI.from
                    ? styles["active"]
                    : ""
                }`}
              >
                {workingDay.from}
              </Button>
              <span className={styles["up-row-divider"]}>-</span>
              <Button
                disabled={!workingDay.checked}
                onClick={() => {
                  setSelected({
                    id: workingDay.day,
                    type: DateFormI.to,
                  });
                }}
                className={`${styles["up-row-tab"]} ${
                  selected &&
                  selected.id === workingDay.day &&
                  selected.type === DateFormI.to
                    ? styles["active"]
                    : ""
                }`}
              >
                {workingDay.to}
              </Button>
            </div>
            {selected && selected.id === workingDay.day && (
              <UpTimePicker
                optionGroups={TimeOptionGroups}
                valueGroups={
                  formatStringToTimeFormat(
                    workingDay[selected.type]!
                  ) as unknown as {
                    [key: string]: string;
                  }
                }
                onChange={(name: string, value: string) => {
                  setWorkingHours(
                    workingHours.map((el) => {
                      if (el.day === workingDay.day) {
                        el[selected.type] = changeAMPMString(
                          el[selected.type]!,
                          name,
                          value
                        );
                      }
                      return el;
                    })
                  );
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default WorkingHours;
