import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList, TaskPriority } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import { LocationViewI, TasksListI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { Chip, IconButton, Link } from "@mui/material";

import TickIcon from "../../../../assets/icons/tick.svg";
import styles from "./TasksCards.module.scss";
import { checkDate12AMto00 } from "../../../../shared/helpers/time.helper";
import moment from "moment";

function TasksCards({ location }: { location: LocationViewI }) {
  const { currentUser } = useContext(UserDataContext);
  const navigate = useNavigate();
  const {
    tasks: content,
    id: locationId,
    name
  }: {
    tasks: TasksListI,
    id: string,
    name: string
  } = location;

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-header"]}>
        <h1 className={styles["up-header-title"]}>Tasks</h1>
        <Chip
          label={content.count}
          classes={{
            root: styles["up-header-chip"],
            label: styles["up-header-chip-label"],
          }}
        />

        {content.items.length > 0 &&
          <Link
            onClick={() =>
              !locationId
                ? navigate("/tasks")
                : navigate(`/tasks/location/${locationId}`)
            }
            underline="none"
            classes={{ root: styles["up-header-link"] }}
          >
            All Tasks
          </Link>
        }

        {isUserHavePermissions(currentUser!, [PermissionsList.createTask]) && (
          <IconButton
            color="inherit"
            classes={{ root: styles["up-header-add"] }}
            onClick={() =>
              locationId
                ? navigate(`/tasks/create?locId=${locationId}&locTitle=${name}`)
                : navigate(`/tasks/create`)
            }
          >
            <AddIcon />
          </IconButton>
        )}
      </div>

      {content.items.length ? (
        <div className={styles["up-grid"]}>
          {content.items.map((i) => (
            <div
              className={styles["up-grid-item"]}
              key={i.id}
              onClick={() => navigate(`/tasks/${i.id}`)}
            >
              <div className={styles["up-grid-item--header"]}>
                <span className={i.priority === 'high' ? styles.high : ''}>{TaskPriority[i.priority as 'regular' | 'high']}</span>, Deadline - {moment(i.deadline).format('ll')}
              </div>
              <div className={styles["description"]}>
                <p className={`${styles["content"]} up-truncate-6`}>
                  {i.content}
                </p>

                {i.completionDate && (
                  <div className={styles["status"]}>
                    <img src={TickIcon} alt="tick" />
                    Completed <br />
                    {checkDate12AMto00(
                      i.completionDate,
                      currentUser!.timezone.value
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className={styles["up-grid-no-items"]}>
          No tasks yet.
        </p>
      )}
    </div>
  );
}

export default TasksCards;
