import axios from "axios";
import AddressField from "components/Shared/Forms/AddressField/AddressField";
import AgentsListField from "components/Shared/Forms/AgentsListField/AgentsListField";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import GeolocationField from "components/Shared/Forms/GeolocationField/GeolocationField";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import UpAvatar from "components/Shared/Profile/Avatar/Avatar";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { WorkingHoursMocked } from "shared/MockedData/Profile.mocked";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import {
  getCompaniesList,
  getCompanyNameId,
  getSettingsList,
  getTimezones,
} from "shared/Services";
import {
  addressValidation,
  phoneValidation,
} from "shared/Validation/Validation";
import * as Yup from "yup";

import {
  FileI,
  LocationFormI,
  LocationViewI,
  PriorityListI,
  WorkingHoursItemI,
} from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
} from "@mui/material";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import LocationIcon from "../../../../assets/icons/location.svg";
import styles from "./LocationsForm.module.scss";
import { toast } from "react-toastify";
import WorkingHours from "../../Profile/WorkingHours/WorkingHours";

interface LocationsFormPropsI {
  submitted: number;
  companyId?: string;
  formFinished: (value: LocationFormI) => void;
  formValue?: LocationViewI;
}

function LocationsForm({
                         submitted,
                         formFinished,
                         formValue,
                         companyId,
                       }: LocationsFormPropsI) {
  const { currentUser } = useContext(UserDataContext);
  const [workingHours, setWorkingHours] = useState<WorkingHoursItemI[]>([]);
  const [workingHoursDefault, setWorkingHoursDefault] = useState<
    WorkingHoursItemI[]
  >([]);
  const [companiesOpened, setCompaniesOpened] = useState(false);
  const [companiesList, setCompaniesList] = useState<
    { id: string; title: string }[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<{
    id: string;
    title: string;
  }>(
    formValue
      ? { id: formValue.company.id, title: formValue.company.name }
      : ({} as {
        id: string;
        title: string;
      })
  );
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [avatar, setAvatar] = useState<FileI | null>(null);
  const [priorities, setPriorities] = useState<PriorityListI[]>([]);
  const [timezones, setTimezones] = useState({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    phoneNumbers: Yup.array()
      .of(phoneValidation)
      .required("This field is required"),
    address: addressValidation,
    company: Yup.string().required("This field is required"),
    priority: Yup.string().required("This field is required"),
    geolocation: Yup.object().shape({
      lat: Yup.string().required("This field is required"),
      lng: Yup.string().required("This field is required"),
    }),
    agents: Yup.array().required("This field is required"),
    emails: Yup.array()
      .of(
        Yup.string().required("This field is required").email("Invalid email")
      )
      .required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: formValue?.name,
      phoneNumbers: formValue?.phoneNumbers || [""],
      emails: formValue?.emails || [""],
      address: formValue?.address || {
        address_line: "",
        city: "",
        country_code: "",
        postal_code: "",
        state: "",
      },
      geolocation: {
        lat: formValue?.geolocation.lat || "",
        lng: formValue?.geolocation.lng || "",
      },
      company: formValue?.company.id || "",
      priority: "",
      agents: formValue?.agents?.map((i) => i.id) || [],
      timezone: formValue?.timezone || "none",
      hasTimeSchedule: formValue?.hasTimeSchedule || false,
    } as LocationFormI,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formFinished({ ...values, workingHours, selectedCompany, avatar });
    },
  });

  useEffect(() => {
    submitted && formik.handleSubmit();
  }, [submitted]);

  useEffect(() => {
    getSettingsList().then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.message);
      } else {
        setPriorities(data.priority);
        formik.setFieldValue("priority", formValue?.priority?.id);
      }
    });

    setAvatar({
      binaryData: formValue?.avatar?.url || null,
      arrayBuffer: null,
    });

    if (!formValue?.workingHours) {
      setWorkingHoursDefault(WorkingHoursMocked);
    } else {
      setWorkingHoursDefault(formValue.workingHours);
    }

    getTimezones().then(resp => {
      if (axios.isAxiosError(resp)) {
        toast.error(resp.message);
      } else {
        setTimezones(resp);
      }
    });
  }, []);

  useEffect(() => {
    getCompaniesList(
      pageNumber * 20,
      !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyCompany]) &&
      isUserHavePermissions(currentUser!, [PermissionsList.viewOwnCompany])
        ? currentUser!.uid
        : undefined
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setCompaniesList([...companiesList, ...data.items]);
        setHasMore(data.hasMore);
      }
    });
  }, [pageNumber]);

  useEffect(() => {
    companyId &&
    getCompanyNameId(companyId).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setSelectedCompany(data);
      }
    });
  }, [companyId]);

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-card"]}>
        <UpAvatar
          name={formValue?.name}
          avatar={avatar?.binaryData || null}
          editable={true}
          toggleImage={setAvatar}
          defaultImage={LocationIcon}
        />
      </div>

      <div className={styles["up-form"]}>
        <FormikProvider value={formik}>
          <TextField
            size="medium"
            classes={{ root: styles["up-form-title"] }}
            label="Location name"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            InputProps={{
              classes: { root: styles["up-form-title-input"] },
            }}
            id="title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />

          <div className={styles["up-form-agents"]}>
            <AgentsListField
              selectedAgentsList={formValue?.agents}
              toggleValue={(e) => formik.setFieldValue("agents", e)}
              errors={formik.errors.agents}
              touched={formik.touched.agents}
            ></AgentsListField>
          </div>

          <div className={styles["up-form-address"]}>
            <AddressField
              addressValue={formValue?.address}
              toggleValue={(e) => formik.setFieldValue("address", e)}
              errors={formik.errors.address}
              touched={formik.touched.address}
            ></AddressField>
          </div>

          <div className={styles["up-form-geolocation"]}>
            <GeolocationField
              searchValue={formik.values.address}
              position={formValue?.geolocation}
              toggleValue={(e) => formik.setFieldValue("geolocation", e)}
              errors={formik.errors.geolocation}
              touched={formik.touched.geolocation}
            ></GeolocationField>
          </div>

          <FieldArray name="phoneNumbers">
            {(formHelpers: any) => (
              <div className={styles["up-form-phoneNumbers"]}>
                {formik.values.phoneNumbers?.map((_, index) => (
                  <TextField
                    key={index}
                    classes={{ root: styles["up-form-phoneNumbers-root"] }}
                    label={"Phone number"}
                    variant="outlined"
                    hiddenLabel={true}
                    fullWidth={true}
                    multiline
                    InputProps={{
                      classes: { root: styles["up-form-phoneNumbers-input"] },
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            formik.values.phoneNumbers?.length > 1 &&
                            formHelpers.remove(index)
                          }
                          className={styles["up-form-phoneNumbers-delete"]}
                        >
                          <img src={DeleteIcon} alt="Delete" />
                        </IconButton>
                      ),
                    }}
                    id={`phoneNumbers.${index}`}
                    name={`phoneNumbers.${index}`}
                    value={formik.values.phoneNumbers[index]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumbers &&
                      Boolean(
                        formik.errors.phoneNumbers
                          ? formik.errors.phoneNumbers[index]
                          : false
                      )
                    }
                  />
                ))}
                <Button
                  classes={{ root: styles["up-form-phoneNumbers-add"] }}
                  variant="outlined"
                  startIcon={<AddIcon color="inherit" />}
                  onClick={() => formHelpers.push("")}
                >
                  Add Telephone
                </Button>
                <FormHelperText>
                  {formik.touched.phoneNumbers &&
                    formik.errors.phoneNumbers &&
                    (typeof formik.errors.phoneNumbers === "string"
                      ? formik.errors.phoneNumbers
                      : formik.errors.phoneNumbers.filter((i) => i.length)[0])}
                </FormHelperText>
              </div>
            )}
          </FieldArray>

          <FieldArray name="emails">
            {(formHelpers: any) => (
              <div className={styles["up-form-emails"]}>
                {formik.values.emails?.map((_, index) => (
                  <TextField
                    key={index}
                    classes={{ root: styles["up-form-emails-root"] }}
                    label={"Email"}
                    variant="outlined"
                    hiddenLabel={true}
                    fullWidth={true}
                    multiline
                    InputProps={{
                      classes: { root: styles["up-form-emails-input"] },
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            formik.values.emails?.length > 1 &&
                            formHelpers.remove(index)
                          }
                          className={styles["up-form-emails-delete"]}
                        >
                          <img src={DeleteIcon} alt="Delete" />
                        </IconButton>
                      ),
                    }}
                    id={`emails.${index}`}
                    name={`emails.${index}`}
                    value={formik.values.emails[index]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.emails &&
                      Boolean(
                        formik.errors.emails
                          ? formik.errors.emails[index]
                          : false
                      )
                    }
                  />
                ))}
                <Button
                  classes={{ root: styles["up-form-emails-add"] }}
                  variant="outlined"
                  startIcon={<AddIcon color="inherit" />}
                  onClick={() => formHelpers.push("")}
                >
                  Add Email
                </Button>
                <FormHelperText>
                  {formik.touched.emails &&
                    formik.errors.emails &&
                    (typeof formik.errors.emails === "string"
                      ? formik.errors.emails
                      : formik.errors.emails.filter((i) => i.length)[0])}
                </FormHelperText>
              </div>
            )}
          </FieldArray>

          <FormControl className={styles["up-form-select"]}>
            <InputLabel>Priority</InputLabel>
            <Select
              classes={{ select: styles["up-form-select-input"] }}
              variant="outlined"
              fullWidth={true}
              id="priority"
              name="priority"
              label={"Priority"}
              value={formik.values.priority}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched?.priority && Boolean(formik.errors?.priority)
              }
            >
              {priorities.map((i) => (
                <MenuItem value={i.uuid} key={i.uuid}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
            {formik.touched?.priority && formik.errors?.priority && (
              <FormHelperText>{formik.errors?.priority}</FormHelperText>
            )}
          </FormControl>

          <TextField
            size="medium"
            classes={{ root: styles["up-form-company"] }}
            label="Company"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            onClick={() => setCompaniesOpened(true)}
            InputProps={{
              classes: { root: styles["up-form-company-input"] },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            id="company"
            name="company"
            value={selectedCompany.title}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />

          <Modal open={companiesOpened} onClose={() => {
          }}>
            <div className={styles["up-modal"]}>
              <FormsHeader
                title="Pick Company"
                position="relative"
                onCancelToggle={() => setCompaniesOpened(false)}
                onRightBtnToggle={() => setCompaniesOpened(false)}
              />
              <InfiniteScroll
                dataLength={companiesList.length}
                next={() => setPageNumber(pageNumber + 1)}
                hasMore={hasMore}
                height={"calc(100vh - 50px)"}
                loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
              >
                {companiesList.length ? (
                  companiesList.map((i) => (
                    <div
                      className={`${styles["up-modal-company-item"]} ${
                        i.id === selectedCompany?.id ? styles["active"] : ""
                      }`}
                      key={i.id}
                      onClick={() => {
                        formik.setFieldValue("company", i.id);
                        setSelectedCompany(i);
                        setCompaniesOpened(false);
                      }}
                    >
                      {i.title}
                    </div>
                  ))
                ) : (
                  <div className="up-no-items">No company records found</div>
                )}
              </InfiniteScroll>
            </div>
          </Modal>

          <div>
            <div className={styles["up-form-has-time-schedule"]}>
            <span className={styles["up-form-has-time-schedule--label"]}>
              Has time schedule
            </span>
              <Switch
                color="default"
                value={formik.values.hasTimeSchedule}
                checked={formik.values.hasTimeSchedule}
                onChange={(e) => {
                  formik.setFieldValue("hasTimeSchedule", e.target.checked);
                }}
                classes={{
                  root: styles["up-switch"],
                  thumb: styles["up-switch-thumb"],
                  track: styles["up-switch-track"],
                  switchBase: styles["up-switch-switch-base"],
                  checked: styles["up-switch-checked"],
                }}
              />
            </div>

            <div className={`${styles["up-form-work-schedule"]} ${formik.values.hasTimeSchedule ? styles["expanded"] : ''}`}>
              <FormControl className={styles["up-form-select"]}>
                <InputLabel>Timezone</InputLabel>
                <Select
                  native
                  classes={{ select: styles["up-form-select-input"] }}
                  variant="outlined"
                  fullWidth={true}
                  id="timezone"
                  name="timezone"
                  label="Timezone"
                  value={formik.values.timezone}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                >
                  <option aria-label="None" value="none">None</option>

                  {Object.entries(timezones).map(([continent, value]) => {
                    if (typeof value === "string") {
                      return <option value={continent} key={continent}>{value}</option>;
                    } else {
                      return <optgroup label={continent} key={continent}>
                        {Object.entries(value as ArrayLike<unknown>).map(([key, text]) =>
                          <option
                            className={styles["up-form-select-option"]}
                            value={key}
                            key={key}>
                            {text as string}
                          </option>)}
                      </optgroup>;
                    }
                  })}
                </Select>
              </FormControl>

              <div className={styles["up-form-working-hours"]}>
                <label className={styles["up-form-working-hours-title"]}>
                  Working hours
                </label>

                <div className={styles["up-form-working-hours-block"]}>
                  {workingHoursDefault.length ? (
                    <WorkingHours
                      hoursChanged={setWorkingHours}
                      hours={workingHoursDefault}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </FormikProvider>
      </div>
    </div>
  );
}

export default LocationsForm;
