import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import UpTimePicker from "components/Shared/ScrollablePicker/ScrollablePicker";
import { FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { DateFormI } from "shared/Enums";
import { checkDateValidation } from "shared/helpers/common.helper";
import { formatTimeStringToDate } from "shared/helpers/profile.helper";
import {
  formatStringToTimeFormat,
  TimeFormatToString,
} from "shared/helpers/time.helper";
import { TimeOptionGroups } from "shared/MockedData/TimePicker.mocked";
import { editTimeReservation, getTimeReservations } from "shared/Services";
import * as Yup from "yup";

import { TimeFormatI, TimeReservationDto } from "@interfaces";
import { TextField } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers-pro";

import styles from "./TimeReservation.module.scss";
import { toast } from "react-toastify";

const moment = require("moment-timezone");
function TimeReservation() {
  const { currentUser } = useContext(UserDataContext);
  const navigate = useNavigate();
  const [date, setDate] = useState<Date | null>(new Date());

  const [timeReservationsObj, setTimeReservationsObj] = useState<{
    id: string;
    days: TimeReservationDto[];
  } | null>(null);

  const [timeValue, setTimeValue] = useState<{
    from: TimeFormatI;
    to: TimeFormatI;
    selected: DateFormI;
  }>({
    from: formatStringToTimeFormat("10:00 AM"),
    to: formatStringToTimeFormat("6:00 PM"),
    selected: DateFormI.from,
  });

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      description: "",
    } as { description: string },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        !checkDateValidation(
          TimeFormatToString(timeValue.from),
          TimeFormatToString(timeValue.to)
        )
      ) {
        toast.error("End time can't be earlier than start time");
      } else {
        editTimeReservation(timeReservationsObj!.id, [
          ...(timeReservationsObj?.days || []),
          {
            date: moment
              .utc(date)
              .tz(currentUser!.timezone.value)
              .format("YYYY-MM-DD"),
            end_time: formatTimeStringToDate(
              TimeFormatToString(timeValue.to),
              currentUser!.timezone.value
            ),
            start_time: formatTimeStringToDate(
              TimeFormatToString(timeValue.from),
              currentUser!.timezone.value
            ),
            title: values.description,
          },
        ]).then((data: AxiosError | any) => {
          if (axios.isAxiosError(data)) {
            toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
          } else {
            toast.success("Submitted!");
            navigate("/profile");
          }
        });
      }
    },
  });

  useEffect(() => {
    getTimeReservations(currentUser!.uid).then(
      (data: AxiosError | { id: string; days: TimeReservationDto[] }) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
          navigate("/profile");
        } else {
          setTimeReservationsObj(data);
        }
      }
    );
  }, []);

  return (
    <>
      <FormsHeader
        title="Time Reservation"
        rightBtnText="Save"
        onRightBtnToggle={() => formik.handleSubmit()}
        position={"fixed"}
        onCancelToggle={() => navigate("/profile")}
      />

      <div className={styles["up-container"]}>
        <FormikProvider value={formik}>
          <TextField
            classes={{ root: styles["up-form-description"] }}
            placeholder="description"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            InputProps={{
              classes: { root: styles["up-form-description-input"] },
            }}
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />

          <div className={styles["up-form-block"]}>
            <div className={styles["up-form-picker"]}>
              <StaticDatePicker
                className={"mat-calendar"}
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={date}
                minDate={new Date()}
                onChange={setDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>

            <label className={styles["up-form-time-label"]}>
              Reserve time slot
            </label>

            <div className={styles["up-form-time-block"]}>
              <div className={styles["up-form-time-tabs"]}>
                <div className={styles["up-form-time-tabs-block"]}>
                  <label className={styles["up-form-time-tabs-label"]}>
                    Start
                  </label>

                  <div
                    onClick={() => {
                      setTimeValue({
                        ...timeValue,
                        selected: DateFormI.from,
                      });
                    }}
                    className={`${styles["up-form-time-tabs-field"]} ${
                      timeValue.selected === DateFormI.from
                        ? styles["active"]
                        : ""
                    }`}
                  >
                    {TimeFormatToString(timeValue.from)}
                  </div>
                </div>
                <span className={styles["up-form-time-tabs-divider"]}>-</span>
                <div className={styles["up-form-time-tabs-block"]}>
                  <label className={styles["up-form-time-tabs-label"]}>
                    End
                  </label>

                  <div
                    onClick={() => {
                      setTimeValue({
                        ...timeValue,
                        selected: DateFormI.to,
                      });
                    }}
                    className={`${styles["up-form-time-tabs-field"]} ${
                      timeValue.selected === DateFormI.to
                        ? styles["active"]
                        : ""
                    }`}
                  >
                    {TimeFormatToString(timeValue.to)}
                  </div>
                </div>
              </div>

              <UpTimePicker
                optionGroups={TimeOptionGroups}
                valueGroups={
                  timeValue[timeValue.selected] as unknown as {
                    [key: string]: string;
                  }
                }
                onChange={(name: string, value: string) =>
                  setTimeValue({
                    ...timeValue,
                    [timeValue.selected]: {
                      ...timeValue[timeValue.selected],
                      [name]: value,
                    },
                  })
                }
              />
            </div>
          </div>
        </FormikProvider>
      </div>
    </>
  );
}

export default TimeReservation;
