import { Container } from "@mui/material";

import Logo from "../../../assets/Logo-blue.svg";
import styles from "./Auth.module.scss";
import AuthRouter from "./AuthRouter";

function Auth() {
  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-logo"]}>
        <img src={Logo} alt="Navi Mate" />
      </div>
      <Container maxWidth="md" classes={{ root: styles["up-content"] }}>
        <AuthRouter />
      </Container>
    </div>
  );
}

export default Auth;
