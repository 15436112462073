import * as Yup from "yup";

export const phoneValidation = Yup.string().matches(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  "Enter correct phone number!"
);

export const linkValidation = Yup.string()
  .required("This field is required")
  .matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Enter correct url!"
  );

export const addressValidation = Yup.object().shape({
  address_line: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  country_code: Yup.string().required("This field is required"),
  postal_code: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
});

export const addressValidationWithoutRequired = Yup.object().shape({
  address_line: Yup.string(),
  city: Yup.string(),
  country_code: Yup.string(),
  postal_code: Yup.string(),
  state: Yup.string(),
});
