import axios from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import UpTabs from "components/Shared/Tabs/Tabs";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import {
  CountriesStatesContext,
  UserDataContext,
} from "shared/Contexts";
import { PermissionsList, TabsStatuses } from "shared/Enums";
import {
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import {
  getLocationName,
  getTasksList,
  getTasksTabsInfo,
} from "shared/Services";

import { LocationNameI, TabsI, TaskCardI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { Fab } from "@mui/material";

import styles from "./TasksView.module.scss";
import { toast } from "react-toastify";

function TasksView() {
  const { countriesStates } = useContext(CountriesStatesContext);
  const { currentUser } = useContext(UserDataContext);

  const navigate = useNavigate();
  const params = useParams();

  const [tasksContent, setTasksContent] = useState<TaskCardI[] | null>(null);
  const [tabsContent, setTabsContent] = useState<TabsI | null>(null);
  const [locationInfo, setLocationInfo] = useState<LocationNameI | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [tabStatus, setTabStatus] = useState(TabsStatuses.active);

  const changeTab = (type: TabsStatuses) => {
    setTabStatus(type);
    setPageNumber(0);
  };

  useEffect(() => {
    getTasksList(
      tabStatus,
      pageNumber * 20,
      currentUser!.timezone.value,
      params.locationId,
      {
        id:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
          isUserHavePermissions(currentUser!, [
            PermissionsList.viewMyServiceTask,
          ]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
            ? currentUser!.uid
            : undefined,
        serviceId:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
          isUserHavePermissions(currentUser!, [
            PermissionsList.viewMyServiceTask,
          ])
            ? currentUser!.uid
            : undefined,
      }
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : "");
      } else {
        setIsLoading(false);
        pageNumber > 0 && tasksContent
          ? setTasksContent([...tasksContent, ...data.content])
          : setTasksContent(data.content);
        setHasMore(data.hasMore);
      }
    });
  }, [pageNumber, tabStatus, params]);

  useEffect(() => {
    getContent()
  }, []);

  const getContent = () => {
    setHasMore(true);
    setIsLoading(true);
    setTabStatus(TabsStatuses.active);
    setPageNumber(0);
    getTasksTabsInfo(currentUser!.timezone.value, params.locationId, {
      id:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [
          PermissionsList.viewMyServiceTask,
        ]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
          ? currentUser!.uid
          : undefined,
      serviceId:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask])
          ? currentUser!.uid
          : undefined,
    }).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : "");
      } else {
        setTabsContent(data);
        setIsLoading(false);
      }
    });
    if (params.locationId) {
      getLocationName(params.locationId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : "");
        } else {
          setLocationInfo(data);
        }
      });
    }
  };
  return (
    <div className={styles["up-container"]}>
      <Header
        title={"Tasks"}
        toggleBack={() => navigate(-1)}
        toggleReload={() => getContent()}
      />
      <h2 className={styles["up-title"]}>Tasks</h2>

      {locationInfo && (
        <>
          <div className={styles["up-location-name"]}>{locationInfo.title}</div>
          <div className={styles["up-location-address"]}>
            {formatAddressToString(locationInfo.address, countriesStates)}
          </div>
        </>
      )}
      <LoadingSpinner isLoading={isLoading}>
        <>
          {tasksContent && tabsContent && (
            <UpTabs
              content={tasksContent}
              tabs={tabsContent}
              changeTab={changeTab}
            >
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={tasksContent.length}
                next={() => setPageNumber(pageNumber + 1)}
                hasMore={hasMore}
                loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
              >
                {tasksContent.length ? (
                  tasksContent.map((item, index) => (
                    <div
                      key={index}
                      className={styles["up-card"]}
                      onClick={() => navigate(`/tasks/${item.id}`)}
                    >
                      {item.title}
                    </div>
                  ))
                ) : (
                  <div className="up-no-items">No task records found</div>
                )}
              </InfiniteScroll>
            </UpTabs>
          )}
        </>
      </LoadingSpinner>
      {isUserHavePermissions(currentUser!, [PermissionsList.createTask]) && (
        <Fab
          aria-label={"add task"}
          color={"inherit"}
          classes={{ root: styles["up-fab-root"] }}
          onClick={() =>
            params.locationId
              ? navigate(`/tasks/create/${params.locationId}`)
              : navigate(`/tasks/create`)
          }
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
}

export default TasksView;
