import styles from "./AgentsListView.module.scss";
import Header from "../../../Shared/Header/Header";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../Shared/LoadingSpinner/LoadingSpinner";
import AgentsList from "../../../Shared/Agents/AgentsList/AgentsList";
import { useState } from "react";

export default function AgentsListView() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const reload = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  };

  return <div className={styles["up-container"]}>
    <LoadingSpinner isLoading={isLoading}>
      <Header
        title={"Switch to User"}
        toggleBack={() => navigate("/")}
        toggleReload={reload}
      />

      <AgentsList />
    </LoadingSpinner>
  </div>;
}
