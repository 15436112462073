import { Modal } from "@mui/material";

import Logo from "../../../assets/Splash-logo.svg";
import styles from "./LoadingSplash.module.scss";

function LoadingSplash({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <>
      {isLoading ? (
        <Modal open={isLoading} onClose={() => {}}>
          <div className={styles["loader-container"]}>
            <img src={Logo} alt="Navi Mate" />
          </div>
        </Modal>
      ) : (
        children
      )}
    </>
  );
}

export default LoadingSplash;
