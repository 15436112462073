import axios, { AxiosError } from "axios";
import AppointmentsForm from "components/Shared/Appointments/AppointmentsForm/AppointmentsForm";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { checkDateValidation } from "shared/helpers/common.helper";
import { createAppointment } from "shared/Services";

import { AppointmentCreateFormI } from "@interfaces";

import styles from "./AppointmentsCreate.module.scss";
import { toast } from "react-toastify";

function AppointmentsCreate() {
  const navigate = useNavigate();

  const { currentUser } = useContext(UserDataContext);

  const [submitted, setSubmitted] = useState(0);

  const FormSubmit = (value: AppointmentCreateFormI) => {
    if (
      !value.isAllDay &&
      !checkDateValidation(value.time!.from, value.time!.to)
    ) {
      toast.error("End time can't be earlier than start time");
    } else {
      createAppointment(value, currentUser!.timezone.value).then(
        (response: AxiosError | any) => {
          if (axios.isAxiosError(response)) {
            toast.error(response.response?.data?.errors[0]?.detail);
          } else {
            toast.success("successfully completed!");
            navigate(`/`);
          }
        }
      );
    }
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Create Appointment"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        onCancelToggle={() => navigate(-1)}
      />
      <AppointmentsForm
        submitted={submitted}
        formFinished={FormSubmit}
      ></AppointmentsForm>
    </div>
  );
}

export default AppointmentsCreate;
