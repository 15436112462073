import axios from "axios";
import moment from "moment-timezone";
import RouteView from "components/Pages/Route/Route";
import GMapView from "components/Shared/gMap/gMap";
import LoadingSplash from "components/Shared/LoadingSplash/LoadingSplash";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CountriesStatesContext,
  HeaderContext,
  RouterContext,
  UserDataContext,
  ManagerContext,
} from "shared/Contexts";
import { CountriesStatesContextI } from "shared/Contexts/CountriesStatesContext";
import { HeaderContextI } from "shared/Contexts/HeaderContext";
import { RouterContextI } from "shared/Contexts/RouterContext";
import { ManagerContextI } from "shared/Contexts/ManagerContext";
import { getCountriesAndStates, getMissedAppointment } from "shared/Services";

import { Container, createTheme, Link, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { useJsApiLoader } from "@react-google-maps/api";

import styles from "./Layout.module.scss";
import AddSidenav from "./Sidenav/AddSidenav";
import Sidenav from "./Sidenav/Sidenav";
import { toast } from "react-toastify";

const libraries = process.env.REACT_APP_GOOGLE_LIBRARY?.split(",") || [];

function Layout({ children }: { children: JSX.Element | JSX.Element[] }) {
  const navigate = useNavigate();
  let location = useLocation();

  const { currentUser } = useContext(UserDataContext);
  const [managerContext, setManagerContext] = useState<ManagerContextI>({
    agent: null
  });
  const [headerContext, setHeaderContext] = useState<HeaderContextI>({
    opened: false,
    addOpened: false,
  });

  const [routerValue, setRouterValue] = useState<RouterContextI>({
    opened: false,
    loaded: false,
    gMapOpened: false,
  });
  const [countriesStates, setCountriesStates] =
    useState<CountriesStatesContextI>({});
  const [isLoading, setIsLoading] = useState(true);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAUEpOSpLuffk8S1IBVfn8k77FYXBYjpSg",
    mapIds: ["11a7b148a03577a0"],
    libraries: libraries as ["places"],
  });

  useEffect(() => {
    setRouterValue({ ...routerValue, loaded: isLoaded });
  }, [isLoaded]);

  useEffect(() => {
    setRouterValue({ ...routerValue, opened: false, gMapOpened: false });

    // Gets missed appointments before current day and generates notification message.
    const date = moment().utc().tz(currentUser!.timezone.value);
    getMissedAppointment(date.format("YYYY-MM-DD")).then(resp => {
      if (axios.isAxiosError(resp)) {
        toast.error(resp?.response?.data?.message);
      } else {
        const appointments: ReactNode[] = [];
        for (const [id, value] of Object.entries(resp.data)) {
          const appointment = value as {
            id: number,
            description: string,
            date: string
          };

          appointments.push(<li
            key={id}
            className={styles["up-link"]}
            onClick={e => navigate(`/visits/${id}/edit`)}>
            {appointment.description}
          </li>);
        }
        appointments.length > 0 && !toast.isActive('missed_appointments') && toast.info(<div>
          <div>You have a missed appointment(s).</div>
          <ul className={styles["up-appointment-group"]}>{appointments.map(a => a)}</ul>
        </div>, {
          toastId: 'missed_appointments',
          autoClose: false,
        });
      }
    });
  }, [location]);

  useEffect(() => {
    getCountriesAndStates().then((data) => {
      if (axios.isAxiosError(data)) {
        return;
      }
      setCountriesStates(data.data);
      setIsLoading(false);
    });
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: ["Lato", "Oxygen", "Ubuntu", "Cantarel", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"].join(
        ","
      ),
    },
    palette: {
      primary: {
        main: "#039BE5",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <HeaderContext.Provider value={[headerContext, setHeaderContext]}>
        <CountriesStatesContext.Provider value={{ countriesStates }}>
          <RouterContext.Provider value={[routerValue, setRouterValue]}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ManagerContext.Provider value={[managerContext, setManagerContext]}>
                <LoadingSplash isLoading={isLoading}>
                  <Container
                    maxWidth="md"
                    classes={{ root: styles["up-container"] }}
                  >
                    <Sidenav
                      opened={headerContext.opened}
                      onToggle={(condition) =>
                        setHeaderContext({
                          opened: condition,
                          addOpened: false,
                        })
                      }
                    />

                    <AddSidenav
                      opened={headerContext.addOpened}
                      onToggle={(condition) =>
                        setHeaderContext({
                          opened: false,
                          addOpened: condition,
                        })
                      }
                    />
                    {children}
                    {(currentUser!.agentProfiles || managerContext.agent) && isLoaded && (
                      <div className={!routerValue.opened ? "router-hidden" : ""}>
                        <RouteView />
                      </div>
                    )}
                    {isLoaded && (
                      <div className={!routerValue.gMapOpened ? "router-hidden" : ""}>
                        <GMapView />
                      </div>
                    )}
                  </Container>
                </LoadingSplash>
              </ManagerContext.Provider>
            </LocalizationProvider>
          </RouterContext.Provider>
        </CountriesStatesContext.Provider>
      </HeaderContext.Provider>
    </ThemeProvider>
  );
}

export default Layout;
