import axios from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import LocationsList from "components/Shared/Locations/LocationsList/LocationsList";
import UpAvatar from "components/Shared/Profile/Avatar/Avatar";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CountriesStatesContext,
  UserDataContext,
} from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  checkPermissionsForEdit,
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { getCompanyData } from "shared/Services";

import { CompanyViewI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton } from "@mui/material";

import LocationIcon from "../../../../assets/icons/location.svg";
import styles from "./CompanyView.module.scss";
import { toast } from "react-toastify";

function CompanyView() {
  const navigate = useNavigate();

  const [companyInfo, setCompanyInfo] = useState<CompanyViewI>();

  const [readMore, setReadMore] = useState(false);
  const { countriesStates } = useContext(CountriesStatesContext);
  const { currentUser } = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  const getContent = () => {
    params.companyId &&
    getCompanyData(params.companyId).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length
          ? data.response?.data?.errors[0]?.detail
          : "");
      } else {
        setCompanyInfo(data);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getContent();
  }, []);
  return (
    <>
      <Header
        title={"Company"}
        toggleBack={() => navigate(-1)}
        toggleReload={getContent}
      />
      <LoadingSpinner isLoading={isLoading} height={"300px"}>
        {companyInfo ? (
          <div className={styles["up-container"]}>
            <div className={styles["up-card"]}>
              {checkPermissionsForEdit(
                currentUser!,
                PermissionsList.editAnyCompany,
                PermissionsList.editOwnCompany,
                companyInfo.authorId
              ) && (
                <div className={styles["up-card-edit"]}>
                  <Button
                    classes={{ root: styles["up-card-edit-button"] }}
                    color={"inherit"}
                    onClick={() => navigate("edit")}
                  >
                    Edit
                  </Button>
                </div>
              )}

              <div className={styles["up-card-avatar"]}>
                {companyInfo.avatar ? (
                  <UpAvatar
                    name={companyInfo.name}
                    avatar={companyInfo.avatar?.url || null}
                    defaultImage={LocationIcon}
                  />
                ) : (
                  <img
                    className={styles["up-card-avatar-none"]}
                    alt={companyInfo.name}
                    src={LocationIcon}
                  />
                )}
              </div>

              <div className={styles["up-card-name"]}>{companyInfo.name}</div>
            </div>
            <div className={styles["up-content"]}>
              <label className={styles["up-content-label"]}>Address</label>
              <div className={styles["up-content-value"]}>
                {formatAddressToString(companyInfo.address, countriesStates)}
              </div>

              {companyInfo.phoneNumbers?.length ? (
                <>
                  <label className={styles["up-content-label"]}>
                    Telephones
                  </label>
                  <div className={styles["up-content-value"]}>
                    {companyInfo.phoneNumbers.map((p, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={`tel:${p}`}
                      >
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}

              {isUserHavePermissions(currentUser!, [
                PermissionsList.viewAnyContact,
                PermissionsList.viewOwnContact,
              ]) && companyInfo.contacts.length ? (
                <>
                  <label className={styles["up-content-label"]}>Contacts</label>
                  <div className={styles["up-content-value"]}>
                    {companyInfo.contacts.map((i) => (
                      <div className={styles["up-w-full"]}>
                        <span
                          key={i.id}
                          onClick={() => navigate(`/contacts/${i.id}`)}
                        >
                          {i.name}
                        </span>
                        {i.phoneNumbers?.length ? (
                          <>
                            {i.phoneNumbers.map((p, index) => (
                              <a
                                key={index}
                                rel="noreferrer"
                                target="_blank"
                                href={`tel:${p}`}
                              >
                                {p}
                              </a>
                            ))}
                          </>
                        ) : null}
                        {i.emails?.length ? (
                          <>
                            {i.emails.map((p, index) => (
                              <a
                                key={index}
                                rel="noreferrer"
                                target="_blank"
                                href={`mailto:${p}`}
                              >
                                {p}
                              </a>
                            ))}
                          </>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}

              <label className={styles["up-content-label"]}>Information</label>
              <div
                className={`${styles["up-content-value"]} ${
                  !readMore ? styles["up-truncate"] : ""
                }`}
              >
                {companyInfo.information}
              </div>
              {!readMore && (
                <span
                  className={styles["up-read-more"]}
                  onClick={() => setReadMore(true)}
                >
                  Read More
                </span>
              )}
            </div>
            <div className={styles["up-locations"]}>
              <h2 className={styles["up-locations-title"]}>
                Locations
                <IconButton
                  color="inherit"
                  classes={{ root: styles["up-locations-add"] }}
                  onClick={() =>
                    navigate(`/locations/create/${companyInfo.id}`)
                  }
                  //todo
                >
                  <AddIcon color={"inherit"} fontSize={"small"} />
                </IconButton>
              </h2>
              <LocationsList
                companyId={companyInfo.id}
                locationSelected={(location) =>
                  navigate(`/locations/${location.id}`)
                }
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </LoadingSpinner>
    </>
  );
}

export default CompanyView;
