import axios, { AxiosError } from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import Profile from "components/Shared/Profile/Profile";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import {
  editSpecialWorkingHours,
  editTimeReservation,
  getProfileData,
} from "shared/Services";

import { ProfileI } from "@interfaces";
import { toast } from "react-toastify";

function ViewMyProfile() {
  const [isLoading, setIsLoading] = useState(true);
  const [profileInfo, setProfileInfo] = useState<null | ProfileI>(null);
  const { currentUser } = useContext(UserDataContext);
  const navigate = useNavigate();

  useEffect(() => {
    getProfileData(currentUser!.uid, currentUser!.timezone).then(
      (data: AxiosError | ProfileI) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
        } else {
          setProfileInfo(data);
        }
        setIsLoading(false);
      }
    );
  }, []);

  const deleteTimeReservation = (index: number) => {
    const editedItems = profileInfo!
      .agentProfile!.timeReservation.filter((_, i) => i !== index)
      .map((i) => {
        delete i.id;
        return i;
      });

    editTimeReservation(profileInfo!.agentProfile!.id, editedItems).then(
      (data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
        } else {
          toast.success("Deleted!");
          profileInfo!.agentProfile!.timeReservation =
            profileInfo!.agentProfile!.timeReservation.filter(
              (_, i) => i !== index
            );
          setProfileInfo(profileInfo);
        }
      }
    );
  };
  const deleteSpecialDay = (index: number) => {
    const editedSpecial = profileInfo!
      .agentProfile!.specialDays.filter((_, i) => i !== index)
      .map((item) => {
        return {
          break_hours_end_time: item.break?.to,
          break_hours_start_time: item.break?.from,
          start_date: item.date.from,
          end_date: item.date.to,
          title: item.title,
          working_hours_end_time: item.workingHours?.to,
          working_hours_start_time: item.workingHours?.from,
        };
      });

    editSpecialWorkingHours(profileInfo!.agentProfile!.id, editedSpecial).then(
      (data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
        } else {
          toast.success("Deleted!");
          profileInfo!.agentProfile!.specialDays =
            profileInfo!.agentProfile!.specialDays.filter(
              (_, i) => i !== index
            );
          setProfileInfo(profileInfo);
        }
      }
    );
  };

  return (
    <>
      <Header
        title={"My profile"}
        toggleBack={() => navigate('/')}
        hideReload={true}
        opacity={0}
      />
      <LoadingSpinner isLoading={isLoading} height={"300px"}>
        {profileInfo ? (
          <Profile
            profileInfo={profileInfo}
            deleteSpecialDay={deleteSpecialDay}
            deleteTimeReservation={deleteTimeReservation}
          ></Profile>
        ) : (
          <></>
        )}
      </LoadingSpinner>
    </>
  );
}

export default ViewMyProfile;
