import axios, { AxiosError } from "axios";
import CompaniesForm from "components/Shared/Companies/CompaniesForm/CompaniesForm";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCompany } from "shared/Services";

import { CompanyFormI } from "@interfaces";

import styles from "./CompanyCreate.module.scss";
import { toast } from "react-toastify";

function CompanyCreate() {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(0);

  const FormSubmit = (value: CompanyFormI) => {
    createCompany(value).then((response: AxiosError | any) => {
      if (axios.isAxiosError(response)) {
        toast.error(response.message);
      } else {
        toast.success("successfully completed!");
        navigate(`/companies/${response}`);
      }
    });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Create Company"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        onCancelToggle={() => navigate(-1)}
      />
      <CompaniesForm
        submitted={submitted}
        formFinished={FormSubmit}
      ></CompaniesForm>
    </div>
  );
}

export default CompanyCreate;
