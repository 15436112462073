import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import CompanyCreate from "./CompanyCreate/CompanyCreate";
import CompanyEdit from "./CompanyEdit/CompanyEdit";
import CompanyView from "./CompanyView/CompanyView";

function CompaniesRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      {isUserHavePermissions(currentUser!, [
        PermissionsList.viewAnyCompany,
        PermissionsList.viewOwnCompany,
      ]) && <Route path="/:companyId" element={<CompanyView />} />}

      {isUserHavePermissions(currentUser!, [
        PermissionsList.editAnyCompany,
        PermissionsList.editOwnCompany,
      ]) && <Route path="/:companyId/edit" element={<CompanyEdit />} />}

      {isUserHavePermissions(currentUser!, [PermissionsList.createCompany]) && (
        <Route path="/create" element={<CompanyCreate />} />
      )}

      <Route path={`*`} element={<PageNotFound />} />
    </Routes>
  );
}

export default CompaniesRouter;
