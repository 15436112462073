import { Route, Routes } from "react-router-dom";
import ViewReports from "./ViewReports/ViewReports";

export default function ReportsRouter() {
  return (
    <Routes>
      <Route path="/" element={<ViewReports />} />
    </Routes>
  );
}
