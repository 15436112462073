import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { CheckRoutingPermission } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import EditMyProfile from "./EditMyProfile/EditMyProfile";
import Settings from "./Settings/Settings";
import SpecialDaysCreate from "./SpecialDaysCreate/SpecialDaysCreate";
import TimeReservation from "./TimeReservation/TimeReservation";
import ViewMyProfile from "./ViewMyProfile/ViewMyProfile";

function ProfileRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      <Route path="/" element={<ViewMyProfile />} />
      <Route path="/edit" element={<EditMyProfile />} />
      {CheckRoutingPermission(currentUser!) && (
        <>
          <Route path="/special-days/create" element={<SpecialDaysCreate />} />
          <Route path="/time-reservation" element={<TimeReservation />} />
        </>
      )}
      <Route path="/settings" element={<Settings />} />
      <Route path={`*`} element={<PageNotFound />} />
    </Routes>
  );
}

export default ProfileRouter;
