import Header from "components/Shared/Header/Header";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <>
      <Header title={""} toggleBack={() => navigate('/')} hideReload={true} />
      <div style={{ paddingTop: "70px", textAlign: "center" }}>
        Page not found
      </div>
    </>
  );
}

export default PageNotFound;
