import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ForgotUserPassword } from "shared/Services";
import * as Yup from "yup";

import { Button, Link, TextField } from "@mui/material";

import ErrorIcon from "../../../../assets/icons/snackbar/error.svg";
import SuccessIcon from "../../../../assets/icons/snackbar/success.svg";
import styles from "./ForgotPassword.module.scss";

function ForgotPassword() {
  const navigate = useNavigate();

  const [formState, setFormState] = useState<"untouched" | "success" | "error">(
    "untouched"
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    } as {
      email: string;
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      ForgotUserPassword(values.email, window.location.origin).then((i) => {
        if (axios.isAxiosError(i)) {
          setFormState("error");
        } else {
          if (i.data.error) setFormState("error");
          else setFormState("success");
        }
      });
    },
  });

  return (
    <div className={styles["up-container"]}>
      {formState === "untouched" && (
        <>
          <h2 className={styles["up-title"]}>Reset Password</h2>
          <p className={styles["up-description"]}>
            Enter your email address, that you used for registration, and we
            will send you instructions to retrieve your password.
          </p>
        </>
      )}

      {formState === "error" && (
        <>
          <h2 className={styles["up-title"]}>
            <div className={`${styles["up-title-img"]} ${styles["error"]}`}>
              <img src={ErrorIcon} alt="Error" />
            </div>
            Email not found
          </h2>
          <p className={styles["up-description"]}>
            The email is not registred yet, please try another email.
          </p>
        </>
      )}

      {formState === "success" && (
        <>
          <h2 className={styles["up-title"]}>
            <div className={`${styles["up-title-img"]} ${styles["success"]}`}>
              <img src={SuccessIcon} alt="Success" />
            </div>
            Email sent
          </h2>
          <p className={styles["up-description"]}>
            Email has been sent to {formik.values.email} with further instructions.
          </p>
        </>
      )}

      {formState !== "success" && (
        <form onSubmit={formik.handleSubmit} className={styles["up-form"]}>
          <TextField
            classes={{ root: styles["up-form-email"] }}
            placeholder="Email"
            variant="filled"
            hiddenLabel={true}
            fullWidth={true}
            InputProps={{
              classes: {
                root: styles["up-form-email-input"],
                focused: styles["focused"],
              },
            }}
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Button
            type="submit"
            color={"inherit"}
            classes={{ root: styles["up-form-button"] }}
            variant="contained"
          >
            RESET
          </Button>
        </form>
      )}
      <Link
        underline="none"
        onClick={() => navigate(`/`)}
        classes={{ root: styles["up-form-link"] }}
      >
        Back to Login screen
      </Link>
    </div>
  );
}

export default ForgotPassword;
