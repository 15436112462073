import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";

import styles from "./Dialog.module.scss";

interface DialogPropsI {
  opened: boolean;
  question: string;
  title?: string;
  successBtnText?: string;
  cancelBtnText?: string;
  onToggle: (event: boolean) => void;
}

function UpDialog({
  opened,
  question,
  title,
  successBtnText,
  cancelBtnText,
  onToggle,
}: DialogPropsI) {
  return (
    <Dialog open={opened} onClose={() => onToggle(false)}>
      <>
        {title && <DialogTitle>{"title"}</DialogTitle>}
        <DialogContent className={styles["up-dialog-content"]}>
          <DialogContentText>{question}</DialogContentText>
        </DialogContent>
        <DialogActions className={styles["up-dialog-actions"]}>
          <Button
            classes={{
              root: styles["up-dialog-actions-button"],
              outlined: styles["outlined"],
            }}
            color={"inherit"}
            onClick={() => onToggle(false)}
            variant="outlined"
          >
            {cancelBtnText || "Cancel"}
          </Button>
          <Button
            classes={{
              root: styles["up-dialog-actions-button"],
              contained: styles["contained"],
            }}
            color={"inherit"}
            onClick={() => onToggle(true)}
            variant="contained"
          >
            {successBtnText || "Yes"}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}

export default UpDialog;
