import { formatResponseWarning } from "shared/helpers/common.helper";

import { CompanyFormI, CompanyViewI, ResponseDto } from "@interfaces";

import {
  apiCompaniesList,
  apiCreateCompany,
  apiDeleteCompany,
  apiEditCompanyData,
  apiGetCompanyData,
  apiGetCompanyNameId,
  apiUploadCompanyLogo,
} from "./Api/Company.api.service";

export const getCompaniesList = (pageNumber: number, id?: string | null) => {
  return apiCompaniesList(pageNumber, id)
    .then((resp) => {
      return {
        items:
          resp.data.data?.map(
            (company: { attributes: { label: any }; id: any }) => {
              return {
                title: company.attributes.label,
                id: company?.id,
              } as { id: string; title: string };
            }
          ) || [],
        hasMore: !(resp.data.data?.length < 20),
      };
    })
    .catch((err) => err);
};

export const getCompanyData = (id: string) => {
  return apiGetCompanyData(id)
    .then((resp) => {
      const response = formatResponseWarning(resp.data);
      const companyContacts =
        response.included
          ?.filter(
            (i: ResponseDto<any>) => i.type === "ups_contact--ups_contact"
          )
          ?.map((i: any) => {
            return {
              name: i.attributes.label,
              phoneNumbers: i.attributes.telephones,
              emails: i.attributes.emails,
              id: i.id,
            };
          }) || [];

      const companyAvatar: ResponseDto<any> = response.included?.find(
        (i: ResponseDto<any>) => i.type === "file--file"
      );
      return {
        id: response.data.id,
        name: response.data.attributes.label,
        address: response.data.attributes.address,
        phoneNumbers: response.data.attributes.telephones,
        avatar: companyAvatar
          ? {
              url: companyAvatar.attributes.uri.url,
              id: companyAvatar.id,
            }
          : null,
        information: response.data.attributes.description.value,
        contacts: companyContacts,
        authorId: response.data.relationships.uid.data.id,
      } as CompanyViewI;
    })
    .catch((err) => err);
};

export const getCompanyNameId = (id: string) => {
  return apiGetCompanyNameId(id)
    .then((resp) => {
      const response = formatResponseWarning(resp.data);
      return {
        id: response.data.id,
        title: response.data.attributes.label,
      } as { id: string; title: string };
    })
    .catch((err) => err);
};

export const editCompanyData = (id: string, body: CompanyFormI) => {
  if (body.avatar?.arrayBuffer) {
    return apiUploadCompanyLogo(id, body.avatar.arrayBuffer)
      .then((data) => {
        editCompanyDataWithAvatar(id, body);
      })
      .catch((err) => err);
  } else if (!body.avatar) {
    return editCompanyDataWithAvatar(id, body, true);
  }
  return editCompanyDataWithAvatar(id, body);
};
export const editCompanyDataWithAvatar = (
  id: string,
  body: CompanyFormI,
  deleteAvatar?: boolean
) => {
  const image = deleteAvatar
    ? {
        relationships: {
          image: {
            data: null,
          },
        },
      }
    : {};

  return apiEditCompanyData(id, {
    data: {
      type: "ups_company--ups_company",
      id: id,
      attributes: {
        address: body.address,
        label: body.title,
        telephones: body.phoneNumbers,
        description: body.information,
      },
      ...image,
    },
  }).catch((err) => err);
};

export const deleteCompany = (id: string) => {
  return apiDeleteCompany(id).catch((err) => err);
};

export const createCompany = (body: CompanyFormI) => {
  return apiCreateCompany({
    data: {
      type: "ups_company--ups_company",
      attributes: {
        address: body.address,
        label: body.title,
        telephones: body.phoneNumbers,
        description: body.information,
      },
    },
  })
    .then((response) => {
      if (body.avatar?.arrayBuffer) {
        return apiUploadCompanyLogo(
          response.data.data.id,
          body.avatar.arrayBuffer
        )
          .then(() => {
            return response.data.data.id;
          })
          .catch((err) => err);
      }

      return response.data.data.id;
    })
    .catch((err) => err);
};
