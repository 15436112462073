import axios from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import {
  CountriesStatesContext,
  UserDataContext,
} from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { checkDate12AMto00 } from "shared/helpers/time.helper";
import { getLocationName, getNotesList } from "shared/Services";

import { LocationNameI, NoteCardI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Fab, InputAdornment, TextField } from "@mui/material";

import styles from "./NotesView.module.scss";
import { toast } from "react-toastify";

const moment = require("moment-timezone");

function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function NotesView() {
  const { currentUser } = useContext(UserDataContext);
  const { countriesStates } = useContext(CountriesStatesContext);
  const params = useParams();

  const [content, setContent] = useState<NoteCardI[]>([]);
  const [locationInfo, setLocationInfo] = useState<LocationNameI | null>(null);

  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    params.locationId &&
      getLocationName(params.locationId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : "");
        } else {
          setLocationInfo(data);
        }
      });
  }, []);

  const reload = () => {
    setHasMore(true);
    setIsLoading(true);
    setPageNumber(0);
    setContent([]);
    getContent();
  };
  const getContent = () => {
    if (searchText === "" || pageNumber !== 0) {
      getNotesList(pageNumber * 20, "", {
        userId:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
          !isUserHavePermissions(currentUser!, [
            PermissionsList.viewMyServiceNote,
          ]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
            ? currentUser!.uid
            : undefined,
        serviceId:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
          isUserHavePermissions(currentUser!, [
            PermissionsList.viewMyServiceNote,
          ])
            ? currentUser!.uid
            : undefined,
        locationId: params.locationId,
      }).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : "");
        } else {
          if (pageNumber === 0) {
            setContent([...data.content]);
          } else {
            setContent([...content, ...data.content]);
          }
          setIsLoading(false);
          setHasMore(data.hasMore);
        }
      });
    } else {
      setIsLoading(true);
      debouncedCallEndpointRef.current(searchText, pageNumber * 20, content);
    }
  };

  useEffect(() => {
    getContent();
  }, [pageNumber, searchText]);

  const debouncedCallEndpointRef = useRef(
    debounce((searchText, pageNumber, content) => {
      getNotesList(pageNumber * 20, searchText, {
        userId:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
          !isUserHavePermissions(currentUser!, [
            PermissionsList.viewMyServiceNote,
          ]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
            ? currentUser!.uid
            : undefined,
        serviceId:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
          isUserHavePermissions(currentUser!, [
            PermissionsList.viewMyServiceNote,
          ])
            ? currentUser!.uid
            : undefined,
        locationId: params.locationId,
      }).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : "");
        } else {
          setContent([...data.content]);
          setHasMore(data.hasMore);
          setIsLoading(false);
        }
      });
    }, 500)
  );

  return (
    <div className={styles["up-container"]}>
      <Header
        title={"Location Notes"}
        toggleBack={() => navigate(-1)}
        toggleReload={reload}
      />
      {locationInfo && (
        <>
          <div className={styles["up-location-name"]}>
            {locationInfo?.title}
          </div>
          <div className={styles["up-location-address"]}>
            {formatAddressToString(locationInfo.address, countriesStates)}
          </div>
        </>
      )}
      <TextField
        hiddenLabel={true}
        fullWidth={true}
        onChange={(e) => setSearchText(e.target.value)}
        InputProps={{
          classes: {
            root: styles["up-search-root"],
            input: styles["up-search-input"],
          },
          endAdornment: (
            <InputAdornment
              position={"end"}
              classes={{ root: styles["up-search-icon"] }}
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant={"filled"}
        placeholder={"Search"}
      />

      <LoadingSpinner isLoading={isLoading} height="300px">
        <div className={styles["up-content"]}>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={content.length}
            next={() => setPageNumber(pageNumber + 1)}
            hasMore={hasMore}
            loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
          >
            {content.length ? (
              content.map((i) => (
                <div
                  className={styles["up-content-card"]}
                  key={i.id}
                  onClick={() => navigate(`/notes/${i.id}`)}
                >
                  <div className={styles["up-content-card-header"]}>
                    {i.task && (
                      <span className={styles["up-content-card-header-task"]}>
                        {i.task}
                      </span>
                    )}
                    <span className={styles["up-content-card-header-date"]}>
                      {checkDate12AMto00(i.date, currentUser!.timezone.value)}
                    </span>
                  </div>
                  <p className={`${styles["content"]} up-truncate-6`}>
                    {i.content}
                  </p>
                  <div className={styles["up-content-card-footer"]}>
                    <span className={styles["up-content-card-footer-author"]}>
                      {i.author}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="up-no-items">No note records found</div>
            )}
          </InfiniteScroll>
        </div>
      </LoadingSpinner>

      {isUserHavePermissions(currentUser!, [PermissionsList.createNote]) && (
        <Fab
          aria-label={"add note"}
          color={"inherit"}
          classes={{ root: styles["up-fab-root"] }}
          onClick={() =>
            params.locationId
              ? navigate(`/notes/create/locations/${params.locationId}`)
              : navigate("/notes/create")
          }
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
}

export default NotesView;
