import axios from "axios";

export const jsonAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL! + "/jsonapi",
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
  withCredentials: true,
});

export const helperApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL! + "/api/v1/helper",
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
  withCredentials: true,
});

export const customApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL! + "",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const customAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL! + "",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const jsonApiFile = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL! + "/jsonapi",
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/octet-stream",
    "Content-Disposition": 'file; filename="avatar.png"',
  },
  withCredentials: true,
});
