import { createContext } from "react";

export interface CountriesStatesContextI {
  [key: string]: {
    name: string;
    states: { state: string; state_code: string }[];
  };
}
export const CountriesStatesContext = createContext<{
  countriesStates: CountriesStatesContextI;
}>({ countriesStates: {} });
